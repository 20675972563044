.content-historique-admin-card-item {
	display: flex;
	align-items: center;
	background-color: #f8f5f3;
}
.date-historique-admin-card {
	width: auto;
	padding: 20px;
}
.title-date-historique-admin-card {
	color: #667085;
	font-family: "Nunito";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}
.content-text-card-historique-admin {
	width: 80%;
	padding: 20px;
	border-left: 2px solid #e4e7ec;
}
.card-item-info-historique-admin {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.text-hour-historique-admin {
	color: #667085;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.text-info-historique-admin {
	color: #667085;
	font-family: "Nunito";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.btn-detail-historique-admin,
.btn-detail-historique-admin:hover {
	color: #e59b34;
	font-family: "Nunito";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.container-body-modal-historique-admin {
	border-bottom: 1px solid #e4e7ec;
}
.container-body-modal-historique-admin:last-child {
	border-bottom: 0 !important;
}
.text-detail-historique-admin-modal {
	color: #828282;
	font-family: "Nunito";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.text-detail-historique-admin {
	color: #667085;
	font-family: "Nunito";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.content-chart-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.chart-item-admin-title {
	color: var(--neutral-black, #25282b);
	font-family: DM Sans;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 26px;
	letter-spacing: 0.2px;
}
.chart-item-admin-week-indicator-container {
	background: #ffffff;
	box-shadow: 0px 1.98221px 9.91103px rgba(0, 0, 0, 0.07);
	border-radius: 9.91103px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 27%;
	padding: 5px 15px;
}

.chart-item-admin-week-indicator-container .react-datepicker-wrapper {
	width: 90%;
}

.chart-item-admin-week-indicator-container
	.react-datepicker-wrapper
	.react-datepicker__input-container {
	width: 100%;
}

.chart-item-admin-week-indicator-container
	.react-datepicker-wrapper
	.react-datepicker__input-container
	.chart-item-admin-week-indicator {
	width: 100%;
	border: none !important;
}

.chart-item-admin-week-indicator {
	font-style: normal;
	font-weight: 400;
	font-size: 11.8932px;
	line-height: 18px;
	letter-spacing: 0.396441px;
	color: #252733;
}

/* .chart-item-admin-week-indicator-statut {
  width: 6px;
  height: 6px;
  background: #da7943;
  border-radius: 9.91103px;
} */

.fc-timeGridWeek-button.btn-type-date {
	border-radius: 9px 0px 0px 9px !important;
}

.btn-type-date {
	padding: 5px 15px;
	box-shadow: 0px 1.98221px 9.91103px rgba(0, 0, 0, 0.07);
	font-size: 13px !important;
}

.fc-timeGridDay-button.btn-type-date {
	border-radius: 0px 9px 9px 0px !important;
}

.fc-toolbar-chunk:first-child {
	display: flex;
	justify-content: space-between;
	width: 50%;
}
.fc-prev-button,
.fc-next-button {
	background: transparent !important;
	color: #e59b34 !important;
	border: 0 !important;
}

.fc-dayGridMonth-button,
.fc-timeGridWeek-button,
.fc-timeGridDay-button {
	background: none !important;
	border: 0 !important;
	color: rgba(0, 0, 0, 0.7) !important;
	font-family: "Nunito" !important;
	font-size: 18.098px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: normal !important;
}

.fc-button-active {
	background: #e5f3ff !important;
	background: #fff !important;
	box-shadow: 0px 3.492783308029175px 3.492783308029175px 0px rgba(229, 179, 54, 0.1) !important;
	color: var(--bleu, #e59b34) !important;
	font-family: "Nunito" !important;
	font-size: 18.098px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: normal !important;
}
.container-title-dash,
.mon-doc-btn-filter-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
@media (max-width: 767px) {
	.content-chart-title {
		flex-direction: column;
	}
	.chart-item-admin-title {
		font-size: 20px !important;
	}
  .chart-item-admin-week-indicator-container {
    width: auto !important;
    margin-bottom: .5rem;
  }
  .content-select-date-graph {
    margin-bottom: .5rem;
  }
}
