.banner-header-page-container {
	background-image: url(../../../assets//appImages/bannerHeader.png);
	background-size: cover;
	background-position: 50%;
	background-repeat: no-repeat;
	width: 100%;
	padding-top: 100px;
	padding-bottom: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 140px;
}
.banner-header-title {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 900;
	font-size: clamp(32px, 4vw, 64px);
	line-height: 61px;
	letter-spacing: -1px;
	color: #ffffff;
}
.content-text-section-apropos-container {
	margin: auto;
}
.content-text-section-apropos-paragraphe {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 232%;
	text-align: center;
	letter-spacing: 0.04em;
	color: #000000;
}
.section-a-propos-qsn {
	padding-top: 20px;
}
.section-notre-equipe-qsn-image {
	width: 100%;
	height: 250px;
}
.section-notre-equipe-qsn-image-container {
	background: #ffffff;
	box-shadow: 0px 3.62115px 30.7797px rgba(0, 0, 0, 0.16);
	border-radius: 6.18996px;
	padding-left: 0px;
	padding-right: 0px;
}
.section-notre-equipe-qsn-role {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 12.674px;
	line-height: 121.2%;
	text-align: center;
	text-transform: uppercase;
	color: #000000;
}
.section-notre-equipe-qsn-titre-img {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 16.2952px;
	line-height: 121.2%;
	text-align: center;
	text-transform: uppercase;
	color: #283262;
}
