.yeewuna-page-service-titre-secondaire-paragraphe {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 179.4%;
  letter-spacing: -0.025em;
  color: rgba(0, 0, 0, 0.5);
}
.yeewuna-page-service-titre-secondaire {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  display: flex;
  align-items: center;
  color: #0c0c0c;
}

.yeewuna-page-service-btn-action {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 10px;
  height: 53px;
  background: #283262;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  width: fit-content;
}
.yeewuna-page-service-btn-action:hover {
  color: #283262;
  border: 1px solid #283262;
}
.yeewuna-page-service-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.yeewuna-page-service-img {
  width: 360.24px;
  height: 362.28px;
}
.yeewuna-page-service-obtenir-devis-container {
  background: rgba(40, 50, 98, 0.1);
  padding: 100px;
}
.yeewuna-page-service-obtenir-devis-col-left {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  padding-right: 100px;
}
.yeewuna-page-service-obtenir-devis-col-right {
  width: 70%;
  padding: 20px 30px 20px 80px;
}
.yeewuna-page-service-obtenir-devis-col-container {
  width: 100%;
  display: flex;
}
.yeewuna-page-service-obtenir-devis-col-left-bg-yellow {
  width: 240px;
  height: 450px;
  background: #e59b34;
  border-radius: 17.8451px;
  position: relative;
}
.yeewuna-page-service-obtenir-devis-col-left-image {
  border-radius: 16px;
  width: 280px;
  position: absolute;
  left: 35px;
  top: 16px;
}
.yeewuna-page-service-obtenir-devis-col-right-titre {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 121%;
  text-align: start;
  color: #18181b;
}
.custom-class-input {
  padding-top: 10px;
  padding-bottom: 10px;
}
.yeewuna-page-service-btn-submit-container {
  display: flex;
  justify-content: flex-end;
  /* padding-top: 42px; */
}

@media (max-width: 768px) {
  .yeewuna-page-service-obtenir-devis-col-right {
    width: 100%;
    padding: 2px;
  }
  .yeewuna-page-service-obtenir-devis-container {
    padding: 10px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .yeewuna-page-service-obtenir-devis-col-left {
    width: 40%;
  }
  .yeewuna-page-service-obtenir-devis-col-right {
    width: 60%;
  }
  .yeewuna-page-service-obtenir-devis-container {
    padding: 50px 20px;
  }
}

.modal-title {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 121%;
    text-align: center;
    color: #18181B;
}
.custom-modal-header {
    display: flex;
    justify-content: center;
    border: none;
}
.modal-content {
    padding: 25px
}

.description-commande .ql-editor {
  min-height: 200px;
}