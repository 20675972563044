.text-version-footer-admin {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14.6182px;
	line-height: 22px;
	color: #000000;
}
.text-copyright-admin-footer {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 12.0385px;
	line-height: 18px;
	color: #000000;
}
.footer-admin-conatiner {
	background: #f1f1f1;
	padding: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	width: 100%;
	bottom: 0;
}
.admin-page-container-height {
	min-height: 90vh;
	margin-bottom: 100px;
	margin-top: 100px;
	padding: 20px;
}
.admin-page-container-layout .ql-editor {
	min-height: 135px !important;
}
.faq-text p {
	color: rgba(0, 0, 0, .5) !important;
}
