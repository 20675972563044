.progress-item .progress-bar {
  background: #e59b34;
}

.title-detail-livre-admin {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: justify;
  color: #283262;
}

.text-describe-detail-livre,
.text-describe-detail-livre p,
.text-describe-detail-livre h1,
.text-describe-detail-livre h2,
.text-describe-detail-livre h3,
.text-describe-detail-livre h4,
.text-describe-detail-livre strong,
.text-describe-detail-livre span,
.text-describe-detail-livre label {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
}

.content-head-detail-livre {
  display: flex;
  gap: 60px;
  align-items: center;
}

.color-theme-text-describe-livre {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #e59b34;
}

.info-auteur-detail-livre-item {
  display: flex;
  align-items: center;
  gap: 20px;
}