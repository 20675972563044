.text-policy-admin,
.text-policy-admin p {
	font-family: "Inter";
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 209.9%;
	color: #000000;
	text-align: justify;
}
.admin-table .tabs-container {
	display: flex;
	align-items: center;
	gap: 20px;
	border-bottom: 2px solid #f5f6fa;
	padding-left: 0;
}
.admin-table .tab-item {
	list-style: none;
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #647891;
}
.admin-table .tab-active-item {
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #e59b34 !important;
	border-bottom: 2px solid #e59b34 !important;
	padding: 15px;
}
.btn-offer-dropdown {
	background-color: transparent;
	border: none;
}
.dropdown-menu.dropdown-menu-start-custom {
	transform: translate(-140px, 28px) !important;
}
.offer-price {
	font-size: clamp(15px, 3vw, 30px) !important;
}

.container-title-dash .custom-title {
	color: rgba(0, 0, 0, 0.22);
	font-family: DM Sans;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 28.8px;
	letter-spacing: -0.5px;
	padding-bottom: 2rem;
}

.custom-form-container .custom-label {
	color: #5a5a5a;
	font-family: DM Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.all-permissions-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-auto-flow: row dense;
  gap: 15px 10px;
}

.all-permissions-content .permission-small-card {
  border-radius: 10px;
  border: 2px solid #E1E1E1;
  background: #FFF;
  transition: all .3s ease;
  padding: 13px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.all-permissions-content .permission-small-card label {
  color: #868686;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.all-permissions-content .permission-small-card:is(:hover, :focus, ::selection) {
  border-color: var(--primary-default, #e59b34);
}

.permission-input {
  accent-color: #e59b34;
}