.yeewuna-parcours-utilisateur-titre-communaute {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 33px;
	text-align: justify;
}
.yeewuna-parcours-utilisateur-communaute-btn-nouvelle-publication {
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;
	background: #283262;
	box-shadow: 0px 20px 50px rgba(191, 21, 108, 0.05);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 25px;
	gap: 10px;
	border: none;
}
/* .yeewuna-parcours-utilisateur-communaute-btn-nouvelle-publication-container {

} */
.yeewuna-parcours-utilisateur-communaute-btn-filter-container {
	display: flex;
	gap: 12px;
	justify-content: flex-start;
}
.utilisateur-communaute-btn-filter.active {
	background: #283262;
  color: #ffffff;
}
.utilisateur-communaute-btn-filter {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 15px;
	display: flex;
	align-items: center;
	letter-spacing: 0.05em;
	text-transform: capitalize;
	color: #808080;
	padding: 5px 15px;
	background: #eaeaea;
	border-radius: 100px;
	gap: 5px;
}
.communaite-article-item-avatar-icone-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.communaite-article-item-username {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0.05em;
	color: #000000;
}
.communaite-article-item-time {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 14px;
	display: flex;
	align-items: center;
	letter-spacing: 0.05em;
	color: #808080;
}
.communaite-article-item-avatar-container {
	display: flex;
	gap: 12px;
	align-items: center;
}
.communaite-article-item-avatar {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}
.communaite-article-item-titre {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	letter-spacing: 0.05em;
	color: #000000;
}
.communaite-article-item-paragraphe {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 25px;
	letter-spacing: 0.05em;
	color: #000000;
}

.communaite-article-item-tags-container {
	display: flex;
	align-items: center;
	gap: 12px;
	flex-wrap: wrap;
}
.communaite-article-item-tag {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
	letter-spacing: 0.05em;
	color: #808080;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 5px 10px;
	background: #eaeaea;
	border-radius: 5px;
}
.communaite-article-item-container {
	background: #ffffff;
	border: 1px solid #eaeaea;
	box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	padding: 25px 30px;
}
.communaite-article-item-icone {
	color: #808080;
}
.communaite-article-item-tags-stats-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.communaite-article-item-stats-container {
	display: flex;
	align-items: center;
	gap: 12px;
}
.communaite-article-item-stats {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.02em;
	color: #808080;
}
.details-discussion-form-commentaire-container {
	padding: 30px 40px;
	gap: 10px;
	background: #ffffff;
	box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.15);
}
.details-discussion-form-commentaire-btn-container {
	display: flex;
	justify-content: flex-end;
	gap: 12px;
}
.details-discussion-form-commentaire-btn-commenter {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 900;
	font-size: 12px;
	line-height: 14px;
	display: flex;
	align-items: center;
	letter-spacing: 0.02em;
	color: #ffffff;

	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 20px;
	gap: 12px;
	background: #283262;
	border-radius: 5px;
	border: none;
}
.details-discussion-form-commentaire-btn-annuler {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	display: flex;
	align-items: center;
	letter-spacing: 0.02em;
	color: #808080;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 12px 20px;
	gap: 12px;
	background: #eaeaea;
	border-radius: 5px;
	border: none;
}
.details-commentaire-btn-voir-toute {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.02em;
	color: #283262;

	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 10px 0px;
	gap: 5px;
	background: none;
	border: none;
}
.communaite-article-item-avatar-small {
	width: 30px;
	height: 30px;
	border-radius: 50%;
}
.custom-side-color {
	border-left: 5px solid #e59b34;
}
.text-area-nurber-of-caracter {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 11px;
	color: rgba(0, 0, 0, 0.17);
}
.ajouter-publication-btn-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.ajouter-publication-btn-publier-container {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 12px;
}
