.admin-img-pp-user {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.admin-title-user-profil {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: justify;
  color: #000000;
}
.text-admin-profil-user, .text-admin-profil-user p,.text-admin-profil-user strong, .text-admin-profil-user h1, .text-admin-profil-user h2, .text-admin-profil-user h3, .text-admin-profil-user h4, .text-admin-profil-user label, .text-admin-profil-user span {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.6);
}
.btn-delete-page-title-admin {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 154.4%;
  color: #eb5757;
  border: 1px solid #eb5757;
  border-radius: 12px;
  padding: 10px 15px;
}
.btn-delete-page-title-admin:hover {
  background: #eb5757;
  color: #fff;
}
.detail-user-info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text-detail-user-libelle {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
  color: #000000;
}
.text-detail-user-value {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.6);
}
.title-progress-bar-detail-user-admin {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.6);
}
