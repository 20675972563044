body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media (min-width: 320px) and (max-width: 480px) {
	.couverture-title {
		padding: 0 !important;
	}
	.page-couverture-actions-container {
		flex-direction: column;
	}
	.yeewuna-btn-success {
		letter-spacing: 0 !important;
	}
	.subscribe-newsletters-btn-container,
	.play-video-btn-container {
		margin: 15px !important;
	}
	.yeewuna-btn-default {
		text-align: center;
	}
	.play-video-btn-container {
		display: flex;
		justify-content: center;
	}
}

@media screen and (min-width: 1900px) {
	.couverture-title {
		font-size: 100px !important;
		line-height: 110px !important;
	}
	.coming-soon-textual {
		font-size: 55px !important;
	}
	.page-couverture-title {
		margin: 30px 15px !important;
	}
	.yeewuna-btn-success {
		padding: 35px 40px !important;
		font-size: 30px !important;
	}
	.yeewuna-btn-default {
		padding: 35px 40px !important;
		font-size: 40px !important;
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
	}
	.play-video-icon {
		width: 100px !important;
		height: 100px !important;
		display: flex !important;
		padding: 25px !important;
	}
	.subscribe-newsletters-btn-container {
		display: flex !important;
		align-items: center !important;
	}
}
.custom-pagination-component {
	padding-left: 3%;
	padding-right: 2%;
}
.yeewuna-tabs-container .tabs-container {
	padding-left: 4%;
	padding-right: 4%;
	padding-bottom: 2%;
}
.yeewuna-tabs-container {
	margin-left: 1%;
}
.yeewuna-locataire-histo-row {
	margin-left: 1%;
}
.msg-user-infos-container {
	padding-left: 15px;
}
.version-view-container {
	color: #f1b927;
}
.container-gaoana {
	padding: 0% 11%;
	background: rgb(255, 255, 255);
}
.container-yeewuna {
	padding: 0% 2%;
	background: rgb(255, 255, 255);
}
.container-yeewuna2 {
	padding: 0% 7%;
	background: rgb(255, 255, 255);
}
.content-view-start {
	background: rgb(255, 255, 255);
}
.no-link,
.no-link:hover,
.no-link.active {
	text-decoration: none;
	color: inherit;
	border: inherit !important;
}

.alimenter-son-compte-element {
	display: flex;
	justify-content: center;
	background-color: #f5f5f5;
	height: 935px;
	padding-top: 5rem;
}

.choisir-montant,
.mode-paiement {
	/* width: 631px; */
	height: 581px;
	background-color: #fff;
	border-radius: 10px;
}

.detail-paiement {
	width: 631px;
	height: 77%;
	background-color: #fff;
	border-radius: 10px;
}

.choisir-montant input.input-choisir-montant {
	width: 100%;
	font-size: 15px;
	line-height: 22.5px;
}

.choisir-montant h2,
.mode-paiement h2,
.detail-paiement h2 {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 36px;
	line-height: 54px;
	text-align: center;
	color: #000;
}

.choisir-montant label {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 22px;
	color: rgba(0, 0, 0, 0.5);
}

.choisir-montant .form-group-choisir-montant {
	width: 100%;
	border-bottom: 1px solid #c4c4c4;
}

.button-alimenter-son-compte,
.button-payer {
	border-radius: 5px;
	border: 1px solid #f1b927;
	font-family: "Poppins", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.75px;
	transition: 0.3s ease-in-out;
	outline: none;
	padding: 0.7rem 2rem;
}
.button-payer:hover {
	background: #f1b927;
	color: #fff;
}
.button-alimenter-son-compte:focus {
	outline: none;
}
.button-alimenter-son-compte-suivant,
.button-payer {
	background: #f1b927;
	color: #fff;
}

.button-alimenter-son-compte-precedent {
	color: #f1b927;
	background-color: #fff;
}

.button-alimenter-son-compte-precedent:hover,
.button-alimenter-son-compte-precedent:focus {
	color: #f1b927;
}

.button-alimenter-son-compte-suivant:hover {
	color: #fff;
}

.mode-paiement-buttons {
	margin-top: 10rem;
}

.mode-paiement-label {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 22px;
	opacity: 0.7;
	margin: 0;
}

.small-radio {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 15px;
	opacity: 0.3;
	margin: 0;
}

.detail-paiement h5 {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 27px;
}

.modal-text {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 19px;
	opacity: 0.7;
}

.text-modal-dark {
	opacity: 1;
	font-weight: 600;
}

.mode-paiement-modal-button-annuler {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
}

.detail-paiement-carte {
	display: flex;
	align-items: center;
	padding: 7px 10px;
	border-radius: 5px;
	margin-bottom: 1rem;
	cursor: pointer;
}

.detail-paiement-carte-active {
	color: #fff;
	background-color: #f1b927;
	transition: all 2s ease;
}
.mode-paiement-paydunya-img {
	width: 100%;
}

.choisir-montant-titre {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 36px !important;
	line-height: 54px;
	text-align: center;
	color: #000000;
}
.custom-carte-paiement-check-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0px;
	width: 120.56px;
	height: 79.75px;
	background: #ffffff;
	box-shadow: 0px 3.70946px 13.9105px rgba(0, 0, 0, 0.08);
}
.carte-mode-paiement-container {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 15px;
}
.custom-form-check-label-with-text {
	display: flex;
	flex-direction: column;
}
.form-label-modal-custom {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 140%;
	color: #2d3748;
}
.form-control-modal-custom {
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
	background: #fafafa !important;
	border: 1px solid #e2e8f0 !important;
	border-radius: 15px !important;
}
.form-control-modal-custom::placeholder {
	font-family: "Nunito" !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 140% !important;
	color: #a0aec0 !important;
}
.form-select-modal-custom {
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
	background: #fafafa !important;
	border: 1px solid #e2e8f0 !important;
	border-radius: 15px !important;
	padding-right: 20px;
	border: 1px solid #e2e8f0 !important;
	padding-right: 20px !important;
	background: url(./assets/icons/chevrondown.png) 96% 50% / 13px no-repeat #fff !important;
	height: 58px;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 17px !important;
	border-radius: 10px !important;
}
.auth-submit-annuler {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	color: #000000;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 16px 32px;
	gap: 10px;
	width: 121px;
	background: #fff;
	border-radius: 10px;
	border: none;
}

.form-label-user-costom {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #0f2034;
}

.form-control-user-custom {
	font-family: "Inter" !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 17px !important;
	color: #0f2034;
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
	background: #fafafa !important;
	padding: 16px !important;
	border: 1px solid #e2e8f0 !important;
	border-radius: 12px !important;
	background: none !important;
}
.form-control-user-custom::placeholder {
	font-family: "Inter" !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 140% !important;
	color: #647891 !important;
}

.react-select-custom-lecteur__control {
	font-family: "Inter" !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 17px !important;
	color: #0f2034 !important;
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
	background: #fafafa !important;
	padding: 8px 10px !important;
	border: 1px solid #e2e8f0 !important;
	border-radius: 12px !important;
	background: none !important;
}
.author-subscribe-parent-step li {
	width: 100%;
}
.author-subscribe-parent-step {
	gap: 15px !important;
}
.author-subscribe-parent-step li.tab-item.tab-active-item {
	border-bottom: none !important;
}
.yeewuna-devenir-autheur-btn-action-container .auth-submit-btn {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	color: #ffffff;
	padding: 15px 32px;
}
.img-couverture-livre {
	width: 100%;
}
.carte-paiement-pin-input-container {
	padding-left: 50px;
}
.carte-paiement-pin-input-container .kfXJYf:focus {
	border-color: rgb(56, 188, 109);
	box-shadow: rgb(56, 188, 109) 0px 0px 0px 1px;
}
.carte-paiement-pin-input-container .bDYKGf:valid {
	border-color: rgb(56, 188, 109);
	box-shadow: rgb(56, 188, 109) 0px 0px 0px 1px;

	border-color: rgb(56, 188, 109);
	box-shadow: rgb(56, 188, 109) 0px 0px 0px 1px;
	background-color: rgba(25, 135, 84, 0.1);
}
.span-eye-password-log {
	position: absolute;
	right: 4%;
	top: 50%;
	color: #9b9da6;
}
.yeewuna-alert {
	border-left: 6px solid #09132d;
	border-radius: 0.4rem;
	color: #09132d;
	padding: 2rem 1%;
	display: flex;
	align-items: center;
	gap: 5px;
}
.yeewuna-alert-info {
	background-color: rgba(50, 175, 239, 0.1);
}
.yeewuna-alert h4 {
	margin: 0;
	font-family: "Montserrat";
	font-style: normal;
	font-size: clamp(11px, 3vw, 18px);
}

.form-control-admin .ql-editor {
	min-height: 200px;
}

.select-perpage {
	z-index: 3;
	color: #fff;
	background-color: rgb(56, 188, 109);
	border-color: rgb(56, 188, 109);
}

.page-item {
	z-index: 3;
	border-color: rgb(56, 188, 109);
	border-radius: 50%;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 13.3071px;
	line-height: 16px;
	margin-left: 5px;
	margin-right: 5px;
	color: rgb(56, 188, 109);
	background-color: #fff;
}

.page-item.active .page-link {
	background-color: rgb(56, 188, 109) !important;
	color: #fff !important;
}

.page-link {
	padding: 0.375rem 0.75rem;
}

.page-link {
	position: relative;
	display: block;
	color: rgb(56, 188, 109) !important;
	text-decoration: none;
	background-color: #fff;
	border: 1px solid rgb(56, 188, 109) !important;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	border-radius: 50%;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
	border-top-left-radius: inherit;
	border-bottom-left-radius: inherit;
}

.page-item:last-child .page-link {
	border-top-right-radius: inherit;
	border-bottom-right-radius: inherit;
}

/* .bg-status {
  background: #ff561d;
}

.message-btn {
  font-family: "Poppins" !important;
  font-display: "swap" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 9px !important;
  line-height: 11px !important;
  color: #ffffff !important;
  letter-spacing: 0.5px !important;
  border-radius: 100px !important;
  text-transform: uppercase !important;
  border: none !important;
  text-align: center !important;
  border-radius: 100px !important;
} */

.text-contact-profil-admin {
	font-family: "Inter" !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 18px !important;
	line-height: 22px !important;
	color: #0f2034 !important;
	margin-bottom: 0 !important;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 200px;
}

.text-pp-user-profil-admin,
.text-pp-user-profil-admin p,
.text-pp-user-profil-admin span,
.text-pp-user-profil-admin label,
.text-pp-user-profil-admin b,
.text-pp-user-profil-admin h1,
.text-pp-user-profil-admin strong {
	font-family: "Inter" !important;
	font-style: normal !important;
	font-weight: 400;
	font-size: 18px;
	line-height: 32px;
	color: #0f2034 !important;
	margin-left: auto !important;
	margin-right: auto !important;
	/* width: 70%; */
}

.react-select-custom-admin__control {
	background: #fafafa !important;
	border: 1px solid #e2e8f0 !important;
	border-radius: 15px !important;
	padding: 12px 10px !important;
	font-family: "Nunito" !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 140% !important;
	color: #a0aec0 !important;
}

.btn-zoom-container {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	gap: 0.5rem;
	position: fixed;
	width: 30px;
	height: 30px;
	top: 50%;
	right: 40px;
}

.btn-zoom-container .zoom-float {
	width: 30px;
	border: 1px solid transparent;
	font-size: 15px;
	font-weight: 900;
	background-color: #f1b927;
	color: #fff;
	border-radius: 10px;
	text-align: center;
	box-shadow: 2px 2px 3px #999;
}
.btn-zoom-container .zoom-float:disabled {
	background-color: #eecb75 !important;
}