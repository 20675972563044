.user-dash-page-content-container {
  background: #ffffff;
  padding: 20px 34px;
}
.item-stat-dashboad-view {
  background: #ffffff;
  padding: 10px 20px;
  width: 100%;
  position: relative;
  align-items: center;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 15px;
}

.titre-item-stat-dashboad-view {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: #bdbdbd;
}
.chiffre-item-stat-dashboad-view {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: #2d3748;
}
.content-text-item-stat-dashboad-view {
  width: 100%;
}
.content-item-icon-stat-dashboard-view {
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  width: 50px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 25px;
}
.admin-page-container-layout {
  margin-top: 90px;
}
.content-stat-dashboard-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.color-1 {
  background: #e59b34;
}
.color-2 {
  background: #283262;
}
.color-3 {
  background: #63311b;
}
.chart-item-admin-title {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #000000;
}
.text-legend-graph {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #e59b34;
}
.content-graph-admin {
  background: #fff;
  padding: 20px;
}
