.footer-component {
  background: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
}
.container-app-yeewuna {
  padding-left: 4rem;
  padding-right: 4rem;
}
.link-footer {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 174.4%;
  color: #959595;
  padding-bottom: 15px;
  text-decoration: none;
}
footer .copyright {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}

.wrapper-social-media {
  display: flex;
  justify-content: center;
  gap: 15px;
}
.wrapper-social-media a.button:hover, a:hover, a:focus {
  text-decoration: none !important;
}
.button {
  float: left;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: #e59b34;
  overflow: hidden;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
}

.button span {
  font-size: 15px;
  font-weight: 500;
  line-height: 40px;
  margin-left: 10px;
}

.button:hover {
  width: 180px;
  color: #fff;
}

/* .button:nth-child(1):hover .icon {
  background: #3b5998;
}

.button:nth-child(2):hover .icon {
  background: #0077b5;
}

.button:nth-child(3):hover .icon {
  background: #f46f30;
}
.button:nth-child(4):hover .icon {
  background: #1da1f2;
}

.button:nth-child(1) span {
  color: #3b5998;
}

.button:nth-child(2) span {
  color: #0077b5;
}

.button:nth-child(3) span {
  color: #f46f30;
}
.button:nth-child(4) span {
  color: #1da1f2;
} */
.button .icon {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.button .icon i {
  font-size: 18px;
  line-height: 45px;
  transition: all 0.3s ease-in-out;
}

.button:hover i {
  color: #fff;
}
.titlsociamedia {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 174.4%;
  color: #e59b34;
  text-align: center;
}
.btn-scroll-to-top {
  background: #283262;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #fff;
  border: 0;
  font-size: 20px;
}

.container-copyright {
  display: flex;
  justify-content: space-between;
}

.link-footer.active {
  color: #283262 !important;
}