.auth-component {
  background: #f5f5f5;
}
.empty-header {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  padding: 50px;
}
.img-login {
  width: 100%;
  height: 100%;
  display: none;
}
.auth-right-side-container {
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth-form-title {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
  text-align: center;
  color: #000000;
  white-space: nowrap;
}
.text-message-login {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #000000;
}
.auth-form-control {
  background: #fafafa;
  border: 1px solid #e2e8f0;
  border-radius: 15px;
  padding: 15px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #a0aec0;
}

.form-group.auth-form-group {
  margin-bottom: 40px;
}

.forget-password-link {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #e59b34;
  text-decoration: none;
}
.forget-password-link:hover {
  text-decoration: none;
  color: #283262;
  font-weight: bold;
}
.auth-form-message {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #a0aec0;
}
.btn-text-message-login {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #283262;
  text-decoration: none;
}
.btn-text-message-login:hover {
  text-decoration: underline;
  color: #e59b34 !important;
}
.auth-submit-btn {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-size: 10px;
  line-height: 150%;
  padding: 15px;
  color: #ffffff;
  background: #283262;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-submit-btn:hover {
  background: #fff !important;
  color: #283262 !important;
  border: 1px solid #283262 !important;
  text-decoration: none;
}

.auth-row.row .auth-col {
  padding: 0 !important;
}

.form-control:focus {
  box-shadow: none !important;
}
.form-label-auth-login {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #2d3748;
}
.auth-right-side-col {
  background: #fff;
}
.text-login-social-media {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #000000;
}
.container-social-media-login {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.btn-social-media-login {
  background: #ffffff;
  border: 1px solid #e8e6ea;
  border-radius: 15px;
  width: 64px;
  height: 64px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.facebook {
  color: #1877f2;
}
.btn-social-media-login:hover {
  transform: translate(0, -10%);
}
.content-social-media-login-item:nth-child(1) .btn-social-media-login:hover {
  background-color: #1877f2;
  color: #fff;
}
.content-social-media-login-item:nth-child(2) .btn-social-media-login:hover {
  background-color: #ffee50;
}
.content-social-media-login-item:nth-child(3) .btn-social-media-login:hover {
  background-color: #000;
  color: #fff;
}

.btn-social-media-login.apple:hover {
  background-color: #000;
  color: #fff;
}


.switch-container {
  width: 100%;
  max-width: 50px;
  max-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 8px;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.switch-container .switch-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #a0aec0;
  transition: background-color 0.2s ease-in;
}

.switch-container #with-warrenty:checked ~ .switch-bg {
  background-color: #283262;
}

.switch-container .switch-left span {
  font-size: 11px;
  font-weight: 700;
  color: #ffffff;
  font-family: "Ubuntu", sans-serif;
  line-height: 1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.switch-container .switch-right span {
  font-size: 11px;
  font-weight: 700;
  color: #ffffff;
  font-family: "Ubuntu", sans-serif;
  line-height: 1;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.switch-container #with-warrenty:checked ~ .switch-left span {
  opacity: 1;
}
.switch-container #with-warrenty:checked ~ .switch-right span {
  opacity: 0;
}

.switch-container .round-box {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 50%;
  transform: translate(-3px, -50%);
  background-color: #f6f6f6;
  box-shadow: 0 2px 3px rgb(0 0 0 / 15%);
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
}

.switch-container #with-warrenty:checked ~ .round-box {
  transform: translate(25px, -50%);
}
.text-se-souvenir-de-moi {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: #2d3748;
}

.auth-right-side-container {
  overflow-y: scroll;
}
.btn-redirect-to-login {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #a0aec0;
}
.btn-redirect-to-login:hover {
  color: #283262;
  font-weight: 700;
}
.text-message-confirm-mail {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
  border-left: 5px solid #e59b34;
  padding-left: 20px;
}
