.user-dash-filter-container {
  display: flex;
  align-items: center;
}

.user-dash-filter-input {
  height: 45px;
  background: #f6f6f6;
  width: 200px;
  left: 377px;
  top: 223px;
  border-radius: 10px;
  border: none;

  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;

  color: rgba(0, 0, 0, 0.6);
}

.yeewuna-parcours-utilisateur-titre-parcourir-livre {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  text-align: justify;
  color: #070707;
}
.yeewuna-parcours-utilisateur-titre-parcourir-container {
  display: flex;
  align-items: center;
  gap: 40px;
}
.yeewuna-parcours-utilisateur-categorie-titre {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-size: 15px;
  line-height: 20px;
  color: #283262;
}
.yeewuna-parcours-utilisateur-categorie-titre::after {
  content: "";
  width: 38px;
  height: 2px;
  background: #283262;
  position: absolute;
  bottom: -4px;
  left: 0;
  margin: auto;
  border-radius: 100px;
}
.yeewuna-titre-du-livres {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}
.yeewuna-autheur-du-livres {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.0388662px;
  color: rgba(117, 117, 117, 0.8);
}
.yeewuna-couverture-du-livres {
  width: 100%;
  /* min-height: 270px;
  max-height: 270px;
  min-width: 199px; */
  height: 100%;
  object-fit: cover;
}
.yeewuna-livre-item {
  /* width: 199px; */
  min-height: 370px;
  max-height: 380px;
}
.yeewuna-couverture-du-livres-container {
  padding: 0px !important;
}
.yeewuna-titre-du-livre-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.yeewuna-livre-item-body {
  padding: 8px;
}
.yeewuna-titre-du-livres-icone-container {
  display: flex;
  gap: 8px;
  align-items: center;
}
.yeewuna-titre-du-livres-icone {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
}
.yeewuna-rate-star-container {
  display: flex;
  gap: 4px;
}
.yeewuna-rate-star-fill {
  color: #f8bf5c;
}
.yeewuna-rate-star-outline {
  color: #bdbdbd;
}

.carousel-produit-marketplace .react-multiple-carousel__arrow--left {
  right: 0px !important;
  left: 0px !important;
  width: 30px !important;
  background: #283262 !important;
  color: #fff !important;
  box-shadow: 0px 7.03125px 19.5312px rgba(0, 0, 0, 0.06) !important;
}
.carousel-produit-marketplace .react-multiple-carousel__arrow--right {
  right: 30px !important;
  width: 30px !important;
  color: #fff !important;
  background: #283262 !important;
  box-shadow: 0px 7.03125px 19.5312px rgba(0, 0, 0, 0.06) !important;
}
.carousel-produit-marketplace .react-multiple-carousel__arrow {
  top: 40% !important;
  bottom: inherit !important;
}
.carousel-produit-marketplace .react-multi-carousel-list {
  padding-bottom: 0px !important;
}
.carousel-produit-marketplace .react-multi-carousel-dot-list {
  display: none !important;
}
.carousel-produit-marketplace .react-multiple-carousel__arrow::before {
  color: #fff !important;
}
.yeewuna-parcours-utilisateur-titre-parcourir-livre-container {
  display: flex;
  gap: 40px;
  align-items: center;
}

/* ============== CUSTOMISER L'EFFECT HOVER SUR LES LIVRES ================ */

.yeewuna-couverture-du-livres-container {
  position: relative;
  width: 100%;
  background: rgb(55, 55, 55);
  color: #fff;
}
.image {
  opacity: 1;
  display: block;
  width: 100%;
  /* height: auto; */
  height: 210px;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  width: 85%;
}
.yeewuna-couverture-du-livres-container:hover .image {
  opacity: 0.3;
}
.yeewuna-couverture-du-livres-container:hover .middle {
  opacity: 1;
  width: 85%;
}
.yeewuna-couverture-btn-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.yeewuna-couverture-btn {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 11.6308px;
  line-height: 16px;
  color: #ffffff;
  border: 0.646154px solid #ffffff;
  border-radius: 6.46154px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8.4px 12.8923px 8.4px 12.2769px;
  gap: 7.75px;
}
.yeewuna-couverture-btn:hover {
  border: 1px solid #ffffff;
}
.yeewuna-couverture-btn span {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-size: 10.3385px;
  line-height: 14px;
  text-align: right;
  color: #ffffff;
}
.container-yeewuna-couverture-du-livres {
  /* height: 270px;
  width: 199px; */
  height: 100%;
  width: 100%;
}
 .carousel-produit-marketplace .react-multi-carousel-track {
  gap: 15px;
  flex-wrap: no-wrap;
 }