.container-banner-policy {
  background: #224374;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 75px;
}
.title-banner-policy {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 51px;
  color: #ffffff;
}
.text-policy {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 275;
  font-size: 16px;
  line-height: 230%;
  color: #000000;
  text-align: justify;
}
.container-text-policy {
  margin-top: 50px;
  margin-bottom: 50px;
}

.text-policy p, .text-policy em, .text-policy strong, .text-policy blockquote {
  color: #000000;
  font-family: "Poppins";
  font-style: normal;
  text-align: justify;
  line-height: 180%;
}