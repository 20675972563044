.photo-profil-livre-admin-table {
  width: 34px;
  height: 48px;
  border-radius: 2px;
}
.price-admin-table {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #6f767e;
}
