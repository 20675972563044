.banner-page-container {
	background-image: url(../../assets/appImages/banner.png);
	background-size: cover;
	background-position: top;
	background-repeat: no-repeat;
	width: 100%;
	padding-top: 100px;
    padding-bottom: 10px;
}
.title-home-page-banner {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 900;
	font-size: clamp(30px, 4vw, 54px);
	line-height: clamp(34px, 5vw, 56px);
	letter-spacing: -1px;
	color: #ffffff;
}
.text-description-home-page-banner {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 600;
	font-size: 17.6825px;
	line-height: clamp(26px, 5vw, 30px);
	line-height: 30px;
	letter-spacing: -1.10515px;
	color: #ffffff;
}
.btn-connect-banner {
	background: transparent;
	border: 1px solid #ffffff;
	border-radius: 10px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	color: #ffffff;
	transition: all 0.3s ease-out;
	padding: 15px 40px;
}
.btn-connect-banner:hover {
	transition-delay: 0.2s;
	color: #fff;
	background: #f1b927;
}
.col-left-banner-home-page {
	display: flex;
	justify-content: center;
}
.content-col-left-home-page {
	width: 70%;
}

.h2-theme,
.custom-h2-theme {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: clamp(24px, 3vw, 40px);
	line-height: clamp(34px, 3vw, 60px);
	letter-spacing: -0.025em;
	color: #14183e;
}
.h2-theme::after {
	content: "";
	width: 150px;
	height: 6px;
	background: #e59b34;
	position: absolute;
	bottom: -10px;
	left: 0;
	right: 6px;
	margin: auto;
	border-radius: 100px;
}
.custom-h2-theme::after {
	content: "";
	width: 150px;
	height: 6px;
	background: #283262;
	position: absolute;
	bottom: -10px;
	left: 0;
	right: 6px;
	margin: auto;
	border-radius: 100px;
}
.content-img-avantage {
	display: flex;
	align-items: center;
	justify-content: center;
}
.img-avantage-home {
	width: 75%;
	position: relative;
	z-index: 99;
	border-radius: 15px;
}
.avantage-yeewuna-container {
	align-items: center;
}

.avantage-home-page-item {
	background: #ffffff;
	box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.08);
	padding: 15px;
	display: flex;
	gap: 20px;
}
.title-avantage-list-homepage {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 30px;
	letter-spacing: 0.04em;
	color: #000000;
}
.text-avantage-list-homepage {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.04em;
	color: #000000;
	text-align: justify;
}
.btn-see-more {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: #14183e;
	text-decoration: none;
	border: 0;
}
.btn-see-more:hover {
	color: #f1b927;
	text-decoration: underline;
}

.input-form-contact {
	border: 0;
	background: #ffffff;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: rgba(0, 0, 0, 0.2);
	padding: 20px;
}
.title-info-contact {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 25px;
	line-height: 38px;
	color: #000000;
}
.text-info-contact {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 25px;
	color: #000000;
}
.content-contact-info {
	display: flex;
	justify-content: space-between;
}
.title-contact-info-item {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 26px;
	color: #f1b927;
}
.text-contact-info-item {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 26px;
	color: #000000;
}
.container-abonnement-home-page {
	background: #e9ebef;
}
.content-col-abonnement-home-page {
	background: #ffffff;
	border: 1px solid #eaecf0;
	box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
	border-radius: 16px;
	padding: 20px 15px;
}
.icon-head-abonnement {
	width: 40px;
	height: 40px;
	background: #e59b34;
	border-radius: 50%;
	color: #000;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
}
.title-abonnement-card {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 600;
	font-size: 48px;
	line-height: 60px;
	text-align: center;
	letter-spacing: -0.02em;
	color: #101828;
	margin-bottom: 15px;
}
.subtitle-abonnement-item {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
	text-align: center;
	color: rgba(0, 0, 0, 0.6);
}
.text-describe-abonnement-card {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #667085;
	text-align: center;
}
.list-offer li,
.list-offer p {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	/* line-height: 24px; */
	color: #667085;
}
.list-offer {
	min-height: 250px;
}
.list-offer li {
	background: url("../../assets/appImages/check_icon.png") no-repeat left center;
	padding: 5px 0px 5px 35px;
	list-style: none;
	margin: 0;
	vertical-align: middle;
	margin-bottom: 5px;
}
.abonnement-list-tem {
	display: flex;
	gap: 15px;
	align-items: center;
}
.icon-abonnement-item {
	width: 24px;
	height: 24px;
	background: #e59b34;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
}
.btn-card-abonnement {
	background: #283262;
	border: 1px solid #283262;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
	padding: 10px;
	width: 100%;
}
.btn-card-abonnement:hover {
	color: #283262 ;
	background: #fff;
	border: 1px solid #283262;
}
.content-btn-card-abonnement {
	background: #f9fafb;
}
.best-ouvrage-item {
	height: 100%;
	width: 100%;
}
.meilleur-ouvrage-img {
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
	width: 100%;
	/* height: 289px; */
	height: 100%;
	object-fit: cover;
	/* width: 241px; */
}
.content-carousel-meilleur-ouvrage .react-multi-carousel-list {
	padding-bottom: 40px;
}
.react-multiple-carousel__arrow {
	bottom: 10px;
	background: #fff !important;
}
.react-multiple-carousel__arrow--left {
	right: 10% !important;
	left: inherit !important;
}
.react-multiple-carousel__arrow::before {
	color: #e59b34 !important;
}
.content-btn-see-more-oeuvre {
	margin-top: -25px;
}
.title-ouvrage-partout {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: clamp(24px, 3vw, 46px);
	line-height: 121%;
	color: #18181b;
}
.text-ouvrage-partout {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 210%;
	letter-spacing: 0.04em;
	color: #000000;
	text-align: justify;
}
.img-ouvrage-partout {
	width: 100%;
}
.card-auteur-item {
	border: 0;
	background: transparent;
	box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.08);
	border-top-left-radius: 32px;
	border-top-right-radius: 32px;
}
.content-card-img-top-auteur {
	width: 300px;
	height: 296px;
	
}
.card-img-top-auteur {
	border-radius: 32px;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.card-title-auteur {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 28px;
	text-align: center;
	letter-spacing: -0.02em;
	color: #283262;
}
.text-nombre-livre {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 28px;
	letter-spacing: -0.02em;
	color: #283262;
}
.card-text-auteur {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 28px;
	text-align: center;
	letter-spacing: -0.02em;
	color: rgba(24, 25, 69, 0.7);
}
.content-head-card-auteur {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.container-newsletter {
	background: #283262;
	padding-top: 30px;
	padding-bottom: 30px;
}
.title-newsletter {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 600;
	font-size: clamp(20px, 4vw, 33.203px);
	line-height: 44px;
	color: #ffffff;
}
.text-newsletter {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 300;
	font-size: 21.25px;
	line-height: 28px;
	font-feature-settings: "liga" off;
	color: rgba(248, 250, 255, 0.76);
}
.col-right-newsletter {
	display: flex;
	align-items: center;
	gap: 15px;
	width: 100%;
}
.form-control-newsletter {
	background: transparent;
	border: 1px solid #ffffff;
	box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 27px;
	color: #ffffff;
	padding: 10px 15px;
}
.btn-subscribe-newsletter {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	color: #283262;
	background: #ffffff;
	border-radius: 10px;
	padding: 13px;
	transition: all ease-out 300ms;
}
.btn-subscribe-newsletter:hover {
	transform: scale(1.04);
}
.row-newsletter {
	align-items: center;
}
/* .auteur-carousel-container .react-multi-carousel-track {
	gap: 2rem !important;
} */
.content-carousel-meilleur-ouvrage .react-multi-carousel-track  {
	gap: 20px;
}
.content-col-abonnement-home-page .accordion-item {
	border: 0 !important;
	background-color: none !important;
	color: inherit !important;
}
.content-col-abonnement-home-page  .accordion-body, .content-col-abonnement-home-page  .accordion-body .list-offer ul {
	padding: 0 !important;
}
.content-col-abonnement-home-page  .accordion-body {
	margin-top: 10px;
}
.content-col-abonnement-home-page .accordion-button {
	background-color: #e59b34 !important;
    border: 1px solid #e59b34 !important;
    color: #ffffff;
	font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
	padding: 10px !important;
}
.content-col-abonnement-home-page .accordion-button::after {
	background-image: url(../../assets/icons/chevron-en-bas.png) !important;
}