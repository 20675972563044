.yeewuna-blog-card-item a {
	text-decoration: none;
}
.yeewuna-blog-card-item {
	background: #ffffff;
	border: 1px solid rgba(24, 25, 69, 0.1);
	border-radius: 32px;
	padding: 10px;
}
.yeewuna-blog-card-text {
	height: 100px;
}
.yeewuna-blog-card-text,
.yeewuna-blog-card-text p {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 26px;
	letter-spacing: -0.02em;
	color: #000000;
	opacity: 0.7;
}
.yeewuna-blog-card-title {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	letter-spacing: -0.02em;
	color: #000000;
}
.yeewuna-blog-card-categorie {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 20px;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	color: #283262;
}
.yeewuna-blog-card-img {
	border-radius: 32px;
	height: 270px;
	width: 100%;
	object-fit: cover;
}
.yeewuna-blog-card-img-container {
	position: relative;
}
.yeewuna-blog-card-body-container {
	padding: 12px;
}
.yeewuna-blog-card-date-container {
	width: 60px;
	height: 61px;
	background: #ffffff;
	border-radius: 16px;
	position: absolute;
	top: 15px;
	left: 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.yeewuna-blog-card-date {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 25px;
	text-align: center;
	letter-spacing: -0.02em;
	color: #181945;
}
.yeewuna-blog-card-mois {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 19px;
	text-align: center;
	letter-spacing: -0.02em;
	color: rgba(24, 25, 69, 0.6);
}
.flex-c .active > .page-link {
	width: 64px;
	height: 64px;
	background: #283262;
	border-radius: 16px;

	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 25px;
	text-align: center;
	letter-spacing: -0.02em;
	color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
}
.flex-c .page-link {
	width: 64px;
	height: 64px;
	background: #ffffff;
	border: 1px solid rgba(24, 25, 69, 0.1);
	border-radius: 16px;
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 25px;
	text-align: center;
	letter-spacing: -0.02em;
	color: #000000;
	display: flex;
	justify-content: center;
	align-items: center;
}
.flex-c .pagination {
	gap: 10px;
}
.flex-c .page-item:first-child .page-link {
	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
}
.flex-c .page-item:last-child .page-link {
	border-top-right-radius: 16px;
	border-bottom-right-radius: 16px;
}
.blog-img {
	height: 30vh;
	object-fit: cover;
}
.blog-infos-container {
	position: absolute;
	bottom: 10px;
	left: 0;
}
.blog-infos, .btn-view-more {
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	text-align: center;
	text-transform: capitalize;
	color: #ffffff;
	padding: 0.7rem 1rem;
}
.blog-infos.published-date {
	background: #e59b34;
	border: 1px solid #e59b34;
}
.blog-infos.categorie,.btn-view-more {
	background: #283262;
	border: 1px solid #283262;
}
.btn-view-more {
    text-decoration: none;
}
.article-content h1,
.article-content h2,
.article-content h3,
.article-title  {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 40px;
	line-height: 28px;
	letter-spacing: -0.02em;
	color: #000000;
}
.article-content p {
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 146.19%;
	color: #7d7d7d;
}
