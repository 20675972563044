.admin-table table thead tr th {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #6f767e;
}
.admin-table table tbody tr td,
.admin-table table tbody tr td p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #6f767e;
}
.admin-table .table thead tr th,
.admin-table .table tbody tr td {
  text-align: left;
}
.admin-table .table {
  border: transparent;
}
.admin-table table tbody tr {
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
}
.admin-table .table thead {
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
}
.table > :not(:first-child) {
  border-top: none !important;
}
.table thead tr th,
table tbody tr td {
  padding: 20px 25px !important;
  vertical-align: middle;
}

.react-bootstrap-table table {
  table-layout: auto;
}
.btn-action-modal-icon,
.btn-action-modal-icon:hover {
  color: #1f3cad;
  border: none;
}

.with-tooltip {
  position: relative;
}
.with-tooltip:hover::after {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, 0);
}
.btn-contact-student-admin,
.btn-contact-student-admin:hover {
  background: #64789138;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  color: #647891;
  font-size: 20px;
}

.btn-action-modal-icon,
.btn-action-modal-icon:hover {
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-action-icon-delete,
.btn-action-icon-delete:hover {
  color: #fa1414;
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-action-icon-edit,
.btn-action-icon-edit:hover {
  color: #9B51E0;
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-action-icon-send,
.btn-action-icon-send:hover {
  color: #1f3cad;
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-action-modal-icon::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 74%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #1f3cad;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.btn-action-icon-delete::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 74%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #fa1414;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}

.btn-action-icon-edit::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 74%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #9B51E0;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}

.btn-action-icon-send::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 74%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #1f3cad;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.photo-profil-auteur-admin-table {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.img_modepaiement{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.name-auteur-admin-table {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 15px !important;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #6f767e !important;
}

.custom-row-class-admin {
  cursor: pointer;
}
.custom-row-class-admin:hover {
  background: #eead5261;
}
