.icon-user-acount-dashboard {
	color: #768492;
	width: 18px;
	margin-left: 5px;
}
.app-logo {
	width: 100px;
	height: 80px;
	object-fit: cover;
}
.user-acount-dashboard-header {
	font-family: "Inter", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	display: flex;
	align-items: center;
	text-align: right;
	color: #011f3b;
	background: none;
	border: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0;
}
.image-user-acount-dashboard-header {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	margin-right: 15px;
}
button:focus {
	outline: 0px dotted;
	outline: 0px auto -webkit-focus-ring-color;
}
.h-logo-container {
	text-align: center;
}
img.logo-header {
	width: 100px;
	border-radius: 50%;
}
/* #navbarNav {
	margin-left: 180px;
} */
.headering {
	background: #ffffff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
	padding: 10px 70px 10px 90px;
	display: flex;
	align-items: center;
	width: 100%;
}
.navbar-collapse .nav-item {
	padding: 0 55px;
}
.navbar-brand-costum {
	padding: 0;
}
.menu-dropdown.dropdown-menu[data-bs-popper] {
	left: 20px !important;
}

a.menu_dropdown-item.dropdown-item {
	display: inline-block !important;
}

.menu_dropdown-item.dropdown-item:hover {
	opacity: .8 !important;
}
.menu_dropdown-item.dropdown-item.active {
	background-color: transparent !important;
	color: #141b2d;
}
.div-content-header-contact {
	background: #f4efee;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 50px;
}
.menu-link-contact-us {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: -0.468947px;
	color: #ffffff !important;
}
.btn-icon-menu-header {
	color: #000;
}
.btn-icon-menu-header:hover {
	color: #d1692a;
	transition: 0.3s ease-in-out;
}
.text-langage {
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: -0.468947px;
	color: #000000;
}
.img-social-icon-header {
	width: 19.84px;
	height: 19.84px;
	margin-left: 10px;
	margin-right: 10px;
}
.menu-item {
	list-style: none;
	float: left;
	padding: 0 21px;
	position: relative;
}
.menu-link-btn {
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: #000000;
	background: #ffffff;
	padding: 0.5rem;
}

.menu-link {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 34px;
	letter-spacing: 1px;
	color: #141b2d;
	text-transform: uppercase;
	text-decoration: none;
	position: relative;
	white-space: nowrap;
	position: relative;
}
.menu-link.active {
	font-weight: bold;
	color: #141b2d !important;
	border-bottom: 3px solid #141b2d;
}
a.menu-link:hover {
	color: #141b2d !important;
	text-decoration: none;
	border-bottom: 3px solid #141b2d;
}

.bloc-icon-menu {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 15%;
}

.btn-langue-header {
	padding: 0;
}
.dropdown-toggle::after {
	border-top: 0.2em solid !important;
	border-right: 0.2em solid transparent !important;
	border-left: 0.2em solid transparent !important;
	vertical-align: 2px !important;
}
.link-item-header {
	font-family: "Inter";
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	color: #000000;
	padding: 0 20px;
}
.link-item-header:hover {
	color: inherit;
	font-weight: bold;
}
.menu-link-select-header {
	border: none;
	background: inherit;
}
.dropdown-item-menu-header {
	font-family: "Inter";
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 154.4%;
	color: #000000;
}
.dropdown-item-menu-header:hover {
	background: #d1692a;
	color: #fff;
}
.dropdown-item-menu-header:focus {
	background: #d1692a;
	color: #fff;
}

.icon-nav-menu-mobile {
	width: 16.67px !important;
	height: 16.67px !important;
}
.dropdown-item-custom.active {
	background: #d1692a !important;
}
.container-logo {
	margin-left: 19%;
}
.container-logo img {
	width: 76px;
	height: 38px;
}
#scrollbar-nav-mobile {
	height: 100%;
	overflow-y: auto;
}
#scrollbar-nav-mobile::-webkit-scrollbar {
	display: none;
}
.content-nav-mobile {
	height: 350px;
}
/* .form-select-exploitation {
    background: #FFFFFF;
    border: 1px solid #14543C;
    box-sizing: border-box;
    border-radius: 10px;
    border-radius: 10px;
    padding: .375rem 1.2rem !important;
    background: url(../../../images/icons/Shape.png) 90% 47% / 13px no-repeat #fff !important;
    appearance: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    color: #5A5A5A;
} */
.form-select:focus {
	box-shadow: none !important;
	border-color: none !important;
}
#dropdown-header .dropdown-toggle::after {
	display: none;
}
.header-items-container {
	display: flex;
	align-items: center;
}
.header-container-fluid {
	padding-bottom: 15px;
}
.navbar-nav {
	display: flex;
	align-items: center;
}
.navbar-nav .navbar-btn-container .navbar-nav {
	display: flex;
	align-items: center;
}
.nav-item-btn-end {
	display: flex;
	align-items: center;
}
.connected-user-content .dropdown-toggle::after {
	display: none;
}
.user-acount-dashboard-header {
	font-family: "Inter", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	display: flex;
	align-items: center;
	text-align: right;
	color: #011f3b;
	background: none;
	border: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0;
}
.image-user-acount-dashboard-header {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	margin-right: 15px;
}
.item-dropdown-custom {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400 !important;
	font-size: clamp(12px, 1vw, 14px);
	line-height: 18px;
	color: #45595a !important;
	border-bottom: 1px solid #dcdcdc !important;
	padding-top: 15px !important;
	padding-bottom: 15px !important;
	text-transform: uppercase;
}
/* .item-dropdown-custom.active, .item-dropdown-custom:hover {
    background: rgba(191, 55, 113, 0.13) !important;
} */
.user-avatar {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}
.user-name {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #011f3b;
}
.btn-theme-mobile {
	background: #d1692a;
	border-radius: 4px;
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 21px;
	text-align: center;
	color: #ffffff;
	padding: 5px;
}
.bloc-flex-info-user-header {
	display: flex;
	gap: 15px;
}
.btn-register-connect {
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #d1692a;
	border: 0;
	border-radius: 0;
	padding: 10px;
}
.btn-register-connect:hover {
	background: #d1692a;
	color: #fff;
}
.btn-login-connect,
.btn-login-connect.active {
	background: #283262 !important;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.11);
	border-radius: 10px;
	font-family: "Nunito";
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	text-transform: uppercase;
	color: #ffffff !important;
	border: 0;
	padding: 15px 25px;
	white-space: nowrap;
}
.btn-login-connect:hover {
	border: 1px solid #283262;
	color: #283262;
	background: #fff;
}
.btn-theme-light {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: #000000;
	border: 1px solid #000000;
	border-radius: 10px;
	padding: 15px 25px;
}
.btn-theme-secondary {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	letter-spacing: 1px;
	text-transform: uppercase;
	letter-spacing: 1px;
	border-radius: 10px;
	padding: 15px 25px;
	white-space: nowrap;
}
.btn-orange {
	background-color: #e59b34;
	border: 1px solid #e59b34;
	color: #ffffff;
	white-space: nowrap;
}
.btn-orange:hover {
	background-color: #ffffff;
	color: #e59b34;
}
.dropdown-menu[data-bs-popper] {
	left: -60px !important;
}
.header-navbar-contents-container {
	display: flex;
	align-items: center;
}
.banner-infos li {
	list-style-type: decimal;
}
