.container-title-page-admin {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.h3-title-admin {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: justify;
  color: #000000;
  margin: 0;
}
.btn-page-title-admin {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: clamp(14px, 3vw,18px);
  line-height: 150%;
  color: #ffffff;
  background: #283262;
  border-radius: 12px;
  padding: 10px 15px;
}
.btn-page-title-admin:hover {
  color: #283262;
  background: #fff;
  border: 1px solid #283262;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
}
.container-admin-page-table {
  background: #fff;
  padding: 20px;
  min-height: 70vh;
}
.form-label-admin {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #2d3748;
}
.form-control-admin {
  background: #fafafa;
  border: 1px solid #e2e8f0;
  border-radius: 15px;
  padding: 20px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #a0aec0;
}
