.content-col-element-panier-user-item {
  display: flex;
  gap: 5px;
  width: 100%;
  align-items: center;
}
.title-col-element-panier-user-item {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  color: #000000;
}
.describe-livre-panier-user-item {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #283262;
}
.content-img-col-element-panier-user-item {
  height: 128px;
  width: 35%;
}
.img-col-element-panier-user-item {
  width: 100px;
  border-radius: 10px;
  height: 100%;
  object-fit: contain;
}
.btn-counter-item {
  border: 1px solid #bdbdbd;
  color: #000000;
  width: 30px;
  height: 30px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
.num {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  background: #fff;
  border: 1px solid #bdbdbd;
}

.content-input-increment-panier .btn-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}
.content-input-increment-panier button:hover {
  cursor: pointer;
}
.content-input-increment-panier {
  display: flex;
  gap: 20px;
  align-items: center;
}
.text-libelle-detail-panier {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
  color: #000000;
}
.text-value-detail-panier-user {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}
.info-detail-panier-user-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-control-select-panier {
  appearance: auto;
}
.text-libelle-panier {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
  color: #000000;
}
.PaiementComponent-left-side-container,
.PaiementComponent-right-side-container {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}
.PaiementComponent-left-side-title {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: justify;
  color: #283262;
  padding: 20px 20px 0px 20px;
}
.PaiementComponent-left-side-page-container {
  padding: 20px;
}
.PaiementComponent-left-side-titre-payer-avec {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #0a0d13;
}
.custom-tab-item-container {
  display: flex !important;
  flex-direction: row;
  gap: 1rem !important;
}
.custom-tab-item {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #acacac !important;
}
.custom-tab-active-item {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #0a0d13 !important;
}
.bgIKmO {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.lmcMDg::after,
.eLtjmU::after {
  margin: 4px !important;
}
.carte-paiement-text-donnees-personnels {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6);
}
.carte-paiement-custom-form-check label {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #acacac;
}
.carte-paiement-custom-form-check .form-check-input:checked {
  background-color: #283262;
  border-color: #283262;
}
.carte-paiement-text-pin {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
  color: #000000;
  line-height: 20px;
  color: #0a0d13;
}
.kQWrbv:focus {
  border-color: rgb(56,188,109) !important;
  box-shadow: rgb(56,188,109) 0px 0px 0px 1px !important;
}
.jfBcaH:valid {
  border-color: rgb(56,188,109) !important;
  box-shadow: rgb(56,188,109) 0px 0px 0px 1px !important;
  background-color: rgba(25,135,84,0.1);
}
.transfert-paiement-text-piement-choisi {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ACACAC;
}
.transfert-paiement-text-numero-pour-paiement {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 12px;
  color: #0A0D13;
}
.transfert-paiement-numero-pour-paiement {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #0A0D13;
}
.transfert-paiement-text-paiement-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.detail-prix-label {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
  color: #000000;
}
.detail-prix-value {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: right;
  color: #000000;
}
.detail-prix-page-label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.detail-prix-page-container {
  padding: 20px;
}
