.yeewuna-livre-item-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 25px;
	background: #fff;
}
.yeewuna-livre-item-image-container {
	padding: 0px;
}
.yeewuna-livre-item-text-container {
	width: 100%;
	padding: 12px 12px 12px 22px;
}
.yeewuna-livre-item-text-titre-du-livre {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 600;
	font-size: 28px;
	line-height: 38px;
	color: #000000;
}
.yeewuna-livre-item-text-autheur-du-livre {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	letter-spacing: 0.0388662px;
	color: #757575;
}
.yeewuna-livre-item-text-description-du-livre {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	text-align: justify;
	color: #6f767e;
}
.yeewuna-livre-item-text-btn-container {
	display: flex;
	justify-content: flex-end;
	gap: 20px;
}

.yeewuna-livre-item-text-btn-acceder {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 14.4px;
	line-height: 20px;
	color: #423f3c;

	display: flex;
	align-items: center;
	padding: 13px 25px;
	gap: 10px;
	border: 0.8px solid #283262;
	border-radius: 8px;
}
.yeewuna-page-bibliotheque-titre {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 33px;
	text-align: justify;
	color: #000000;
}
.yeewuna-page-bibliotheque-titre-divider {
	color: rgba(0, 0, 0, 0.3);
}

/* ================== CUSTOMISER L'EFFECT HOVER ==================== */

.yeewuna-livre-item-image-container {
	position: relative;
	width: 50%;
	height: 100%;
	background: rgb(55, 55, 55);
	color: #fff;
}
.content-yeewuna-livre-item-image {
	width: 100%;
	height: 100%;
}
.yeewuna-livre-item-image {
	opacity: 1;
	display: block;
	width: 100%;
	height: 100%;
	transition: 0.5s ease;
	backface-visibility: hidden;
	object-fit: cover;
}
.middle {
	transition: 0.5s ease;
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	text-align: center;
	width: 85%;
}
.yeewuna-livre-item-image-container:hover .yeewuna-livre-item-image {
	opacity: 0.3;
}
.yeewuna-livre-item-image-container:hover .middle {
	opacity: 1;
	width: 85%;
}
.yeewuna-couverture-btn-container {
	display: flex;
	flex-direction: column;
	gap: 12px;
}
.yeewuna-couverture-btn {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 11.6308px;
	line-height: 16px;
	color: #ffffff;
	border: 0.646154px solid #ffffff;
	border-radius: 6.46154px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8.4px 12.8923px 8.4px 12.2769px;
	gap: 7.75px;
}
.yeewuna-couverture-btn:hover {
	border: 1px solid #ffffff;
}
.yeewuna-couverture-btn span {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 800;
	font-size: 10.3385px;
	line-height: 14px;
	text-align: right;
	color: #ffffff;
}

.yeewuna-description-livre-titre {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 33px;
	text-align: justify;
	color: #283262;
}

.yeewuna-description-livre-infos-container {
	display: flex;
	flex-direction: row;
}
.yeewuna-description-livre-infos-image {
	max-width: 304.74px;
	max-height: 469px;
	min-width: 300px;
	width: 100%;
	height: auto;
	filter: drop-shadow(0px 4.27701px 25.662px rgba(0, 0, 0, 0.16));
}
.yeewuna-description-livre-infos-image-container {
	padding: 0px;
}
.yeewuna-description-livre-infos-text-titre {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 50px;
	line-height: 68px;
	color: #000000;
}
.yeewuna-description-livre-infos-text-container {
	padding: 0 30px;
}
.yeewuna-description-livre-infos-text-autheur {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: rgba(0, 0, 0, 0.7);
}
.yeewuna-description-livre-infos-text-autheur span {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 25px;
	color: #283262;
}
.yeewuna-description-livre-infos-text-rate {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	line-height: 15px;
	color: rgba(0, 0, 0, 0.6);
}
.yeewuna-description-livre-infos-text-rate-container {
	display: flex;
	gap: 10px;
	align-items: center;
}
.yeewuna-description-livre-infos-text-titre-apropos {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 25px;
	color: #000000;
}
.yeewuna-description-livre-infos-text-paragraphe-apropos,
.yeewuna-description-livre-infos-text-paragraphe-apropos p {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: rgba(0, 0, 0, 0.7);
}
.yeewuna-description-livre-infos-text-caracteristique-container {
	text-align: start;
}
.yeewuna-description-livre-infos-text-caracteristique-value {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 15px;
	line-height: 20px;

	color: rgba(0, 0, 0, 0.6);
}
.yeewuna-description-livre-infos-text-caracteristique-label {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: rgba(0, 0, 0, 0.5);
}

.yeewuna-description-livre-infos-text-btn-container {
	display: flex;
	gap: 12px;
	align-items: center;
}

.yeewuna-description-livre-infos-text-btn {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 14.4px;
	line-height: 20px;
	color: #283262;
	white-space: nowrap;
	border: 0.8px solid #283262;
	border-radius: 8px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10.4px 15px 10.4px 15.2px;
	gap: 9.6px;
}
.yeewuna-description-livre-infos-text-btn:hover {
	border: 0.9px solid #283262;
}
.yeewuna-description-livre-infos-text-btn span {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 12.8px;
	line-height: 17px;
	text-align: right;
	color: #283262;
}
.yeewuna-description-livre-infos-text-titre-icone {
	color: #e59b34;
	font-size: 22px;
}

.yeewuna-description-livre-item-content-auteur-infos {
	display: flex;
	align-items: center;
	gap: 60px;
}
.yeewuna-description-livre-item-content-auteur-profile-container {
	display: flex;
	gap: 12px;
	align-items: center;
}
.yeewuna-description-livre-item-content-auteur-profile-username {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 26px;
	line-height: 35px;
	color: rgba(0, 0, 0, 0.6);
}
.yeewuna-description-livre-item-content-auteur-profile-fonction {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.5);
}
.yeewuna-description-livre-item-content-auteur-date-publication {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #000000;
	display: flex;
	align-items: center;
	gap: 5px;
}
.yeewuna-description-livre-item-content-auteur-profile-image {
	width: 87px;
	height: 87px;
	border-radius: 50%;
}
.yeewuna-description-livre-item-content-btn {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 14.4px;
	line-height: 20px;
	color: #283262;
	display: flex;
	align-items: center;
	border: 0.8px solid #283262;
	border-radius: 8px;
	padding: 10.4px 43.2px 10.4px 15.2px;
	gap: 9.6px;
}

.yeewuna-description-livre-item-content-paragraphe,
.yeewuna-description-livre-item-content-paragraphe p {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 25px;
	text-align: justify;
	color: #6f767e;
}
.yeewuna-description-livre-item-content-btn-en-savoir-plus {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	color: #283262;
}
.yeewuna-description-livre-item-content-btn-en-savoir-plus::after {
	content: "";
	width: 105px;
	height: 2px;
	background: #283262;
	position: absolute;
	bottom: -7px;
	left: 0;
	margin: auto;
	border-radius: 100px;
}
.yeewuna-description-livre-item-content-auteur-infos-container {
	margin-top: 100px;
}
.description-livre-titre-avis {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 600;
	font-size: 28px;
	line-height: 38px;
	color: #283262;
}
.description-livre-avis-image-profile {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.description-livre-avis-profile-username {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 19px;
	line-height: 154.4%;
	color: #333333;
}
.description-livre-avis-profile-date {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 154.4%;
	color: #283262;
}
.description-livre-avis-profile-paragraph {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 19px;
	line-height: 154.4%;
	color: #828282;
}
.description-livre-avis-image-profile-username-container {
	display: flex;
	gap: 30px;
}

.description-livre-avis-profile-icone-container {
	display: flex;
	gap: 40px;
	align-items: center;
}
.description-livre-avis-profile-icone {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #636466;
}

.description-livre-soumettre-avis-titre {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 32px;
	color: #161412;
	margin-bottom: 20px;
	margin-top: 25px;
}
.description-livre-soumettre-avis-titre-aucun-avis {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 32px;
	color: #161412;
	margin: 20px 0px;
}
.description-livre-soumettre-avis-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.description-livre-soumettre-avis-text-area-container {
	width: 100%;
}
.form-control-utilisateur {
	background: #ffffff;
	border: 1px solid #bdbdbd;

	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 154.4%;
	color: #2d3748;
	border-radius: 0px;
}
.form-control-utilisateur::placeholder {
	color: #828282;
}
.yeewuna-custom-icone-accordeon .accordion-button:not(.collapsed)::after {
	background-image: url("../../../../assets/icons/ArrowDown.png");
	transform: var(--bs-accordion-btn-icon-transform);
	width: 20px;
	height: 20px;
	background-color: none !important;
	box-shadow: none !important;
}
.yeewuna-custom-icone-accordeon .accordion-button::after {
	background-image: url("../../../../assets/icons/ArrowDown.png");
	width: 20px;
	height: 20px;
}

.yeewuna-custom-icone-accordeon .accordion-button:not(.collapsed) {
	background-color: #fff;
}
.detail-livre-btn-voir-plus {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 19px;
	text-align: right;
	color: #283262;
	border: none;
	background: none;
	text-decoration: none;
}
.detail-livre-btn-voir-plus:hover {
	text-decoration: underline;
}
.detail-livre-btn-voir-plus-container {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}
.bookmark-btn {
	background-color: transparent;
	border: none;
}

.sweet-container {
	font-size: 1.5rem;
}

.btn-sweet-cancel {
	background-color: #f5f6fd !important;
	color: #283262 !important;
}

.btn-sweet-cancel:hover,
.btn-sweet-cancel:focus {
	color: #f5f6fd !important;
	background-color: #283262 !important;
}

.btn-sweet-confirm,
.btn-sweet-confirm:focus {
	background-color: #1f3cad !important;
	color: #fff !important;
	border: 0.5px solid #1f3cad !important;
}

.btn-sweet-confirm:hover {
	color: #1f3cad !important;
	background-color: #fff !important;
	background-image: none !important;
	box-shadow: 0 0 0 3px transparent !important;
}
.audio-livre-title {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 29.561px;
	line-height: 40px;
	text-align: justify;
	color: #283262;
	text-align: center;
}

.rhap_play-pause-button,
.rhap_play-status--paused,
.rhap_play-status--playing {
	color: #283262 !important;
}
.audio-lecture .rhap_progress-filled {
	background-color: #283262 !important;
}
.audio-lecture .rhap_progress-indicator {
	background-color: #283262 !important;
}
.buy-book-modal-title {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 25.56px;
	line-height: 35px;
	text-align: justify;
	color: #283262;
}

.buy-modal-title {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 21.56px;
	line-height: 29px;
	color: #000000;
}
.buy-modal-text {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: #000000;
}
.content-yeewuna-details-actu-container {
	width: 140px;
	height: 140px;
	margin: auto;
}
.image-yeewuna-details-actu-container {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.highlights-container {
	padding: 15px;
	background: white;
	margin-top: 1rem;
}

.highlights-title {
	color: #283262;
}

.highlights-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
}