.container-profil-auteur {
	background-image: url(../../../../assets/appImages/img-couverture-pp.png);
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.img-profil-auteur-dash {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	border: 5px solid #e59b34;
}

.name-profil-auteur-dash {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 154.4%;
	text-transform: uppercase;
	color: #ffffff;
	text-align: center;
}

.container-info-profil-auteur-dash {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 50px;
}

.info-item-profil-auteur-dash {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 27px;
	color: #ffffff;
}

.info-item-profil-auteur-dash:hover {
	color: #e59b34;
	font-weight: 700;
}

.content-col-about-me {
	background: #ffffff;
	box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
}

.title-col-left-about-me {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 25px;
	color: #000000;
	text-align: center;
}

.text-footer-col-left-about-me {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: #000000;
}

.title-col-right-about-me {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 33px;
	text-align: justify;
	color: #283262;
}

.container-info-oeuvres-about-me {
	display: flex;
	align-items: center;
	gap: 15px;
}

.text-info-oeuvres-about-me-item {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 14px;
	color: #6f767e;
}

.img-oeuvres-about-me {
	width: 100%;
}

.text-describe-oeuvres-about-me,
.text-describe-oeuvres-about-me p {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: #000000;
}

.btn-see-all,
.btn-see-all:hover {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 25px;
	color: #e59b34;
	padding: 10px 0;
}

.container-header-brouillon-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.content-col-abonnement-tabs {
	background: #ffffff;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
	border-radius: 0px;
	border: 0;
}

.btn-desabonnement {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 25px;
	color: #e59b34;
	border: 1px solid #283262;
	border-radius: 10px;
}

.card-title-abonnement {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 27px;
	color: #000000;
	text-align: center;
	margin-top: 3rem;
}

.card-img-top-abonnement-top {
	border-radius: 20px 20px 0px 0px;
}
.border-avatar {
	border: 1px solid #1aa981;
}

.img-pp-abonnement-tabs {
	width: 70px;
	height: 70px;
	border-radius: 50%;
}

.content-img-pp-abonnement-tabs {
	position: absolute;
	top: 25%;
	left: 37%;
}

.change-img-profil-auteur-dash {
	position: relative;
	width: 100px;
	height: 100px;
	border-radius: 50%;
}

.auteur-user-avatar-edit-hover {
	position: absolute;
	background: rgba(255, 255, 255, 0.525);
	width: 96%;
	height: 50%;
	border-radius: 0 0 50% 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 46%;
	opacity: 0;
	transition: opacity 0.2s ease-in-out;
}

.change-img-profil-auteur-dash:hover .auteur-user-avatar-edit-hover {
	opacity: 1 !important;
	cursor: pointer;
}

.content-icon-change-photo-pp-auteur-profil {
	margin-top: -18%;
	font-size: 35px;
	color: #ffffff;
}

.input-change-avatar-auteur-profil {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.pp-profil-user-auteur {
	width: 100%;
	height: 100%;
	border: 5px solid #e59b34;
	border-radius: 50%;
}

.input-change-name-auteur-profil,
.input-change-name-auteur-profil:focus {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 154.4%;
	text-transform: uppercase;
	text-align: center;
	color: #ffffff !important;
	background: rgb(255 255 255 / 0%);
	border: 1px solid #ffffff;
	border-radius: 10px;
	padding: 10px;
	width: 300px;
}

.content-img-profil-auteur-dash-edit {
	display: flex;
	justify-content: center;
}

.text-change-img-bg {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 154.4%;
	text-transform: uppercase;
	color: #ffffff;
}

.content-change-img-background {
	position: absolute;
	right: 4%;
	top: 5%;
}

.profil-bio .ql-editor {
	min-height: 200px;
	background: #fafafa;
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 140%;
	color: #a0aec0;
}

.user-offer-infos {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.user-offer-infos p {
	color: #283262;
	font-size: 15px;
	font-weight: 600;
}

.user-offer-expired-date .text-footer-col-left-about-me {
	font-size: 16px !important;
	font-weight: 600 !important;
	color: #283262 !important;
}