.faq-page-question-title {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: clamp(24px, 3vw,42px);
  line-height: 121%;
  text-align: center;
  color: #18181b;
}
.content-text-section-apropos-container .search-form-container {
  position: relative;
  width: 60%;
  margin: auto;
}
.content-text-section-apropos-container .search-form-content label {
  position: absolute;
  top: 10%;
  left: 4%;
  color: #200e32;
  font-size: 20px;
}
.content-text-section-apropos-container input.form-control.search-input {
  background: #f5f5f7;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14.4px;
  line-height: 20px;
  color: #283262;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10.4px 43.2px 10.4px 65.2px;
  gap: 23px;
  border: 0.8px solid #283262;
  border-radius: 8px;
}
.content-text-section-apropos-container svg.search-icony {
  width: 20px;
  height: 20px;
}
.content-text-section-apropos-container
  input.form-control.search-input::placeholder {
  color: #283262;
}
.page-faq-titre-faq-container {
  padding-top: 60px;
}
.page-faq-titre-faq {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;
}
.page-faq-titre-faq-paragraphe {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #667085;
}
.yeewouna-accordeon-container .accordion-item {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #eaecf0;
}
.yeewouna-accordeon-container .accordion-button {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}
.yeewouna-accordeon-container .accordion-item:last-of-type {
  border-bottom: none;
}
.yeewouna-accordeon-container .accordion-body {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  text-align: start;
}
.yeewouna-accordeon-container .accordion-button:not(.collapsed)::after {
  background-image: url("../../../assets//icons//iconAccMoin.png");
  transform: var(--bs-accordion-btn-icon-transform);
  width: 20px;
  height: 20px;
  background-color: none !important;
  box-shadow: none !important;
}
.yeewouna-accordeon-container .accordion-button:not(.collapsed) {
  background-color: #fff;
}
.yeewouna-accordeon-container .accordion-button:not(.collapsed) {
  background-color: none !important;
  box-shadow: none !important;
}
.yeewouna-accordeon-container .accordion-button::after {
  background-image: url("../../../assets//icons//iconAccPlus.png");
  width: 20px;
  height: 20px;
}
.yeewouna-accordeon-container .accordion-button:focus {
  border-color: #eaecf0;
}
.yeewouna-faq-vous-avez-toujours-des-questions-container {
  background: #283262;
  border-radius: 16px;
  padding-top: 32px;
  padding-bottom: 32px;
}
.yeewouna-faq-vous-avez-toujours-des-questions-titre {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: clamp(24px, 3vw, 32px);
  line-height: 30px;
  text-align: center;
  color: #ffffff;
}
.yeewouna-faq-vous-avez-toujours-des-questions-paragraphe {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}
.yeewouna-faq-vous-avez-toujours-des-questions-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin: auto;
}
