.yeeuna-devenir-autheur-col-left-content-infos-profile {
	background: #283262;
	border-radius: 10px;
	display: flex;
	gap: 12px;
	padding: 20px 18px;
}
.yeeuna-devenir-autheur-col-left-titre-infos-profile {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 27px;
	color: #ffffff;
}
.yeeuna-devenir-autheur-col-left-suggestion-profile {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: #ffffff;
}
.yeeuna-devenir-autheur-col-rigth-form {
	background: #e9ebef;
	border-radius: 10px;
	padding: 40px 30px;
}
.yeewuna-devenir-autheur-col-right-form-titre {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 33px;
	color: rgba(0, 0, 0, 0.9);
}
.yeewuna-devenir-autheur-btn-action-container {
	display: flex;
	justify-content: flex-end;
	gap: 12px;
	align-items: center;
	padding-top: 60px;
}
.yeewuna-devenir-autheur-col-right-form-forfait-actuel {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	text-transform: uppercase;
	color: #000022;
}
.yeewuna-devenir-autheur-col-right-form-forfait-divider {
	margin: 0px;
	color: rgba(0, 0, 34, 0.2);
}
.yeewuna-devenir-autheur-col-right-form-forfait {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 30px;
	color: #000022;
}
.yeewuna-devenir-autheur-col-right-form-pas-de-forfait {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 19px;
	color: rgba(0, 0, 34, 0.4);
}
.yeewuna-devenir-autheur-col-right-form-btn-choisir-abonnement {
	padding: 16px 32px;
	background: #ffffff;
	border-radius: 10px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	color: #4d4c4c;
	border: 1px solid #000;
}
.yeewuna-devenir-autheur-col-right-form-img {
	width: 35px;
	height: 35px;
}
.yeewuna-devenir-autheur-col-right-form-numero-card {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: #000022;
}
.yeewuna-devenir-autheur-col-right-form-numero-card-cacher {
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background: #000022;
}
.yeewuna-devenir-autheur-col-right-form-card-text-expiration-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.yeewuna-devenir-autheur-col-right-form-card-container {
	display: flex;
	align-items: center;
	gap: 12px;
}
.yeewuna-devenir-autheur-col-right-form-numero-card {
	display: flex;
	align-items: center;
	gap: 3px;
}
.yeewuna-devenir-autheur-col-right-form-card-expiration {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: #000022;
}
.yeewuna-devenir-autheur-col-right-form-card-expiration-container {
	display: flex;
	align-items: center;
	gap: 6px;
}
.yeewuna-devenir-autheur-col-right-form-card-expiration-icon {
	color: #aab2c8;
	font-size: 19px;
}
.yeewuna-devenir-autheur-col-right-form-btn-choisir-abonnement-icone {
	font-size: 19px;
}
/* ================== custom tab list devenir-author ============= */
.yeeuna-devenir-autheur-col-left-content-btn-tabs .custom-tabs-container {
	display: flex;
	flex-direction: column;
	background: #fff;
	align-items: flex-start;
	/* margin-top: -16px; */
}

.yeeuna-devenir-autheur-col-left-content-btn-tabs .custom-tabs-container {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
}

.yeeuna-devenir-autheur-col-left-content-btn-tabs .custom-tabs-container li {
	list-style: none;
	display: flex;
	gap: 10px;
	align-items: center;
	transition: all ease-in-out 300ms;
	padding-bottom: 10px;

	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #aab2c8;
	padding: 18px 25px;
	border-radius: 10px;
	position: relative;
	width: 100%;
	border: none;
}
.yeeuna-devenir-autheur-col-left-content-btn-tabs li.tab-active-item {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #e59b34 !important;
	background: #faf0ea !important;
	border: none !important;
}
.yeeuna-devenir-autheur-col-left-content-btn-tabs
	.yeeuna-devenir-autheur-col-left-content-btn-tabs
	ul {
	padding-left: 0px;
}
.yeeuna-devenir-autheur-col-left-content-btn-tabs .tab-item-icone {
	position: absolute;
	right: 20px;
}
/* ================== Fin custom tab list devenir-author ============= */
.custom-input-file-author {
	border: 1px solid #fff !important;
}
.custom-modal .h2-theme {
	font-size: 35px;
}
.custom-modal .h2-theme::after {
	background: #283262;
}
.custom-modal .title-abonnement-card {
	font-size: 28px;
}
.custom-modal .content-text-abonnement-item {
	font-size: 30px;
}
.custom-modal .container-app-yeewuna {
	padding-left: 1rem;
	padding-right: 1rem;
}
.custom-modal .container-abonnement-home-page {
	background: none;
}
.custom-modal .section-avantage-home-page {
	padding-top: 0px;
}
.custom-modal .subtitle-abonnement-item {
	font-size: 16px;
}
.custom-modal .text-describe-abonnement-card {
	font-size: 14px;
}
.custom-modal .icon-abonnement-item {
	width: 20px;
	height: 20px;
}
.custom-modal .icon-head-abonnement {
	width: 35px;
	height: 35px;
}
.custom-modal .text-avantage-list-homepage {
	padding-bottom: 45px !important;
}
/* ====================== CUSTOM PROGRESS CIRCLE =================== */
.mt-100 {
	margin-top: 200px;
}
.yeewuna-progress-container .progress {
	width: 100px;
	height: 100px !important;
	float: left;
	line-height: 100px;
	background: none;
	box-shadow: none;
	position: relative;
}
.yeewuna-progress-container .progress:after {
	content: "";
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border: 6px solid rgba(255, 255, 255, 0.1);
	position: absolute;
	top: 0;
	left: 0;
}
.yeewuna-progress-container .progress > span {
	width: 50%;
	height: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	z-index: 0;
}
.yeewuna-progress-container .progress .progress-left {
	left: 0;
}
.yeewuna-progress-container .progress .progress-bar {
	width: 100%;
	height: 100%;
	background: none;
	border-width: 6px;
	border-style: solid;
	position: absolute;
	top: 0;
}
.yeewuna-progress-container .progress .progress-left .progress-bar {
	left: 100%;
	border-top-right-radius: 80px;
	border-bottom-right-radius: 80px;
	border-left: 0;
	-webkit-transform-origin: center left;
	transform-origin: center left;
}
.yeewuna-progress-container .progress .progress-right {
	right: 0;
}
.yeewuna-progress-container .progress .progress-right .progress-bar {
	left: -100%;
	border-top-left-radius: 80px;
	border-bottom-left-radius: 80px;
	border-right: 0;
	-webkit-transform-origin: center right;
	transform-origin: center right;
	animation: loading-1 1.8s linear forwards;
}
.yeewuna-progress-container .progress .progress-value {
	width: 88%;
	height: 88%;
	border-radius: 50%;
	background: rgba(255, 255, 255, 0.1);
	font-size: 24px;
	color: #fff;
	line-height: 88px;
	text-align: center;
	position: absolute;
	top: 6%;
	left: 6%;
}
.yeewuna-progress-container .progress.blue .progress-bar {
	border-color: #fff;
}
.yeewuna-progress-container .progress.blue .progress-left .progress-bar {
	animation: loading-2 1.5s linear forwards 1.8s;
}
@keyframes loading-1 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}
}
@keyframes loading-2 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(144deg);
		transform: rotate(144deg);
	}
}
@keyframes loading-3 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(135deg);
		transform: rotate(135deg);
	}
}
.description-book .ql-editor p {
	color: #000;
	height: 80px;
}

.tuto-text {
	font-size: 12px;
	font-weight: 500;
	color: #283262;
	/* text-transform: uppercase;
	text-decoration: underline; */
	cursor: pointer;
}

.tuto-text span {
	color: #e59b34;
}
.modal-content .offer-price {
    font-size: clamp(15px, 3vw, 20px) !important;
}
.content-carousel-meilleur-ouvrage .react-multi-carousel-item {
	width: 250px !important;
}