.react-select-custom-admin__control {
	background: #fafafa !important;
	border: 1px solid #e2e8f0 !important;
	border-radius: 15px !important;
	padding: 12px 10px !important;
	font-family: "Nunito" !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 140% !important;
	color: #a0aec0 !important;
}
