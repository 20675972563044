.custom-parent-table-container-message-tabs {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
  border-radius: 17.4159px;
}
.bloc-search-message input {
  width: 100%;
  background: #ffffff;
  border-radius: 19.793px;
  border: 1px solid #fff;
  padding: 15px 0px 15px 65px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 19.5058px;
  line-height: 23px;
  letter-spacing: 0.05em;
  color: #7c7c7c;
}
.bloc-search-message i {
  color: #7c7c7c;
  font-size: 20px;
}
.bloc-search-message button {
  background: transparent;
  position: absolute;
  left: 4%;
  top: 30%;
  border: none;
}
.bloc-search-message button:hover {
  background-color: #f45b64;
}
.bloc-search-message button:hover i {
  color: #fff;
}
.titre-messages-bloc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22.0454px;
  line-height: 33px;
  letter-spacing: 0.05em;
  color: #303030;
}
.empty-chat-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 350px;
}

.btn-send-newsletter {
  display: block;
  text-decoration: none;
  position: relative;
  transition: all 0.35s;
  border-radius: 4px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-left: 10px;
  letter-spacing: 2px;
}
.form-search-user {
  width: 100%;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: rgba(37, 39, 51, 0.8);
  padding: 15px 45px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-bottom: 1px solid #bdbdbd;
}

.user-for-send-message-infos h3 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #303030;
}
.img-profil-user-message {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.input-send-messge {
  border: none;
  background: #fff;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 165%;
  letter-spacing: 0.01em;
  color: #192a3e;
}
.image-profil-entete {
  width: 100%;
  height: auto;
  max-width: 60px;
  max-height: 60px;
  border-radius: 50%;
}
.img-expediteur {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.statut-expediteur {
  background: #2ed47a;
  border-radius: 50%;
  width: 6px;
  height: 6px;
}
.img-messge-contact {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.bloc-reponse {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 2%;
}

.dropdown-menu.status-contact {
  left: -50px !important;
}

.list-group-item-meessage {
  border-bottom: 1px solid #dee2e6;
  padding: 10px inherit;
}
.dtails-messages-tabs-component {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  padding: 3%;
  border-radius: 20px;
}
.dtails-messages-tabs-component .dropdown-toggle::after {
  display: none;
}
.dtails-messages-tabs-component .input-group {
  border-radius: 20px;
  background-color: #eeebea;
  padding: 10px 15px;
}
.dtails-messages-tabs-component .input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
}
.dtails-messages-tabs-component .input-container :nth-child(1) {
  display: flex;
  gap: 10px;
  align-items: center;
}
.dtails-messages-tabs-component input {
  border: 0;
  outline: 0;
  background-color: transparent;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 19.0013px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #303030;
}
.dtails-messages-tabs-component .btn-container button {
  width: 55px;
  height: 55px;
  padding: 19px;
  border-radius: 20px;
  border: 0;
  outline: 0;
  cursor: pointer;
  background-color: #283262;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dtails-messages-tabs-component .left-footer-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dtails-messages-tabs-component .img-icon-chat {
  cursor: pointer;
}
.statut-message-tabs-non-lu {
  background: #f45b64;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 9px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.01em;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.statut-message-tabs-traite {
  color: #2ed47a;
}
.statut-message-tabs-erreur {
  color: #ff3c5f;
}
.parent-table-container-message-tabs {
  height: 700px;
  overflow-y: scroll;
  border-radius: 20px;
  background: #fff;
  padding: 20px;
}
.parent-table-container-message-tabs::-webkit-scrollbar {
  width: 4px;
}
.parent-table-container-message-tabs::-webkit-scrollbar-track {
  background: transparent;
  margin-top: 10px;
}
.parent-table-container-message-tabs::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
  border: 3px solid transparent;
}
.btn-send-message-tabs {
  color: #283262;
}
.btn-send-message-tabs:hover {
  color: #283262;
}
.profil-detail-message {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 21;
  line-height: 33px;
  letter-spacing: 0.05em;
  color: #303030;
}
.time-detail-message {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 15.8344px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: #707070;
}
.bloc-message-recu::after {
  background: #e59b34;
  width: 14.25px;
  height: 14.25px;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 0%;
  bottom: 36%;
}
.text-message-recu {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0.05em;
  color: #fff;
  background: #e59b34;
  border-radius: 17px;
  padding: 10px 15px;
}
.bloc-message-envoyer::after {
  background: #283262;
  width: 14.25px;
  height: 14.25px;
  border-radius: 50%;
  content: "";
  position: absolute;
  right: 0%;
  bottom: 23%;
}
.text-message-envoyer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0.05em;
  color: #ffffff;
  background: #283262;
  border-radius: 17px;
  padding: 10px 15px;
}
.bloc-details {
  width: 100%;
  padding: 40px 20px;
  height: 500px;
  overflow-y: scroll;
}
.bloc-details::-webkit-scrollbar {
  width: 4px;
}
.bloc-details::-webkit-scrollbar-track {
  background: #fff;
}
.bloc-details::-webkit-scrollbar-thumb {
  background-color: #283262;
  border-radius: 20px;
  border: 3px solid transparent;
}
.empty-chat-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 350px;
}
.bloc-profil-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nom-contact {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13.4941px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #303030;
}

.nom-contact.active {
  color: #283262;
}
.time-message-contact {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12.6675px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #7c7c7c;
}
.contenu-message-contact {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12.6675px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #303030;
}
.bloc-message-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .msg-user-infos-container {
      padding-left: 5%;
  } */
.text-disconnect-time {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 13.9327px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: #303030;
}
.container-display-input-message {
  width: 100%;
}
.textarrea-message-custom {
  background: transparent;
  width: 100%;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 15.8344px;
  line-height: 19px;
  letter-spacing: 0.05em;
}
.btn-filter-message {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(15, 32, 52, 0.5);
  border: none;
}
.btn-filter-message.active,
.btn-filter-message:hover {
  color: #000000 !important;
  border: none;
}
.container-btn-filter-message {
  display: flex;
  align-items: center;
  gap: 10px;
}
.btn-send-message-modal {
  background: #283262;
  border-radius: 10px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  padding: 6px 8px;
}
.btn-send-message-modal:hover {
  background: #fff;
  border: 1px solid #ffffff;
  color: #283262;
}
.btn-send-message-modal.disabled {
  background: #e0e0e0;
  color: #ffffff;
  border-color: transparent;
}
.form-search-user {
  width: 100%;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: rgba(37, 39, 51, 0.8);
  padding: 15px 45px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-bottom: 1px solid #bdbdbd;
}
.user-for-send-message {
  list-style: none;
}
.user-for-send-message-infos h4 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #303030;
}

.modal-title-admin {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 154.4%;
  color: #000000;
}
