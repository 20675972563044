/* ================== custom tab list autheur ============= */
.yeewuna-parcours-utilisateur-titre-parcourir-container .yeewuna-tabs-container {
	width: 100%;
}
.yeewuna-parcours-utilisateur-titre-parcourir-container .yeewuna-tabs-container .tabs-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 2rem;
	padding-left: 0px;
}

.yeewuna-parcours-utilisateur-titre-parcourir-container .yeewuna-tabs-container .tabs-container li {
	list-style: none;

	display: flex;
	align-items: center;
	transition: all ease-in-out 300ms;
	padding-bottom: 5px !important;
	padding: initial;
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 27px;
	text-align: justify;

	color: rgba(0, 0, 0, 0.5);
}
.yeewuna-parcours-utilisateur-titre-parcourir-container .yeewuna-tabs-container li.tab-active-item {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 27px;
	text-align: justify;

	color: #000000 !important;

	border-bottom: 2px solid #283262 !important;
	background: none !important;
}

.yeewuna-parcours-utilisateur-titre-parcourir-container .tabs-search-input-container {
	display: flex;
	justify-content: space-between;
}

/* ================== Fin custom tab list autheur ============= */

.content-yeewuna-parcours-utilisateur-auteur-item-image {
	height: 296px;
	width: 100%;
}
.yeewuna-parcours-utilisateur-auteur-item-image {
	width: 100%;
	/* max-height: 296px;
	min-height: 296px; */
	height: 100%;
	border-radius: 32px;
	object-fit: cover;
}
.yeewuna-parcours-utilisateur-auteur-item-body-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.yeewuna-parcours-utilisateur-auteur-item-username {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 28px;
	text-align: center;
	letter-spacing: -0.02em;
	color: #283262;
}
.yeewuna-parcours-utilisateur-auteur-item-username span {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 28px;
	text-align: center;
	letter-spacing: -0.02em;
	color: #e59b34;
}
.yeewuna-parcours-utilisateur-auteur-item-abonne {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 28px;
	text-align: center;
	letter-spacing: -0.02em;
	color: rgba(24, 25, 69, 0.7);
}
.yeewuna-utilisateur-actualite-image-item, .yeewuna-details-actu-image {
	width: 100%;
	min-height: 200px;
	max-height: 200px;
	border-radius: 18.3249px;
	object-fit: cover;
}
.yeewuna-utilisateur-actualite-item-content-container  {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.yeewuna-utilisateur-actualite-item-content-titre {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: clamp(18px, 3vw, 22px);
	line-height: 30px;
	color: #000000;
	width: 90%;
}
.yeewuna-utilisateur-actualite-item-content-paragraphe,
.yeewuna-utilisateur-actualite-item-content-paragraphe p {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: clamp(14px, 3vw, 16px);;
	line-height: 153.4%;
	color: #6f767e;
}
.yeewuna-utilisateur-actualite-item-content-auteur-infos {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.yeewuna-utilisateur-actualite-item-content-auteur-profile-container {
	display: flex;
	gap: 12px;
}
.yeewuna-utilisateur-actualite-item-content-auteur-profile-username {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 27px;
	color: #000000;
}
.yeewuna-utilisateur-actualite-item-content-auteur-profile-fonction {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	color: #000000;
}
.yeewuna-utilisateur-actualite-item-content-auteur-date-publication {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #000000;
	display: flex;
	align-items: center;
	gap: 5px;
}
.yeewuna-utilisateur-actualite-item-content-auteur-profile-image {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}
.yeewuna-utilisateur-actualite-item-content-auteur-date-publication-icone {
	font-size: 18px;
}

/* ===================== CUSTOM INPUT SEARCH PARCOURS UTILISATEUR =================== */

.tabs-search-input-container .search-form-container {
	position: relative;
	width: 20%;
	margin: inherit !important;
}
.tabs-search-input-container .search-form-content label {
	position: absolute;
	top: 0%;
	left: 4%;
	color: #200e32;
	font-size: 20px;
}
.tabs-search-input-container input.form-control.search-input {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 14.4px;
	line-height: 20px;
	color: #283262;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 6px 10px 6px 35px !important;
	gap: 23px;
	border: none;
	background: #f4f4f5;
	border-radius: 4px;
	width: auto !important;
}
.tabs-search-input-container svg.search-icony {
	width: 20px;
	height: 20px;
}
.tabs-search-input-container input.form-control.search-input::placeholder {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: #a1a1aa;
}

.yeewuna-detail-auteur-titre {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 33px;
	text-align: justify;
	color: #000000;
}

.yeewuna-dash-utilisateur-page-content-container {
	background: #ffffff;
	padding: 0px;
	border-radius: 10px 10px 0px 0px;
}
.yeewuna-detail-auteur-img-banniere {
	width: 100%;
	height: auto;
	max-height: 240px;
	border-radius: 10px;
	object-fit: cover;
}
.banner-user {
	width: 100%;
	height: 240px;
	max-height: 240px;
	border-radius: 10px;
	background-color: #e59b34;
}
.yeewuna-detail-auteur-img-banniere-container {
	position: relative;
}
.yeewuna-detail-auteur-img-profile {
	width: 150px;
	height: 150px;
	border: 5px solid #283262;
	border-radius: 50%;
}
.yeewuna-detail-auteur-img-profile-container {
	position: absolute;
	top: 155px;
	left: 40px;
	/* z-index: ; */
}
.yeewuna-detail-auteur-nom-autheur {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 154.4%;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	color: #000000;

	padding-left: 190px;
}
.yeewuna-detail-auteur-page-container {
	padding: 0px 30px 40px;
}
.yeewuna-detail-auteur-statistic-autheur-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 40px;
}
.yeewuna-detail-auteur-statistic-autheur-btn-abonnement {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 25px;
	color: #283262;

	display: flex;
	padding: 13px 25px;
	gap: 10px;
	border: 1px solid #283262 !important;
	border-radius: 10px;
}
.yeewuna-detail-auteur-statistic-autheur-btn-abonnement-container {
	display: flex;
	gap: 12px;
	align-items: center;
}
.btn__primary, .btn__primary:hover {
	background-color: #283262;
	color: #ffffff;
}
.yeewuna-detail-auteur-statistic-text {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 27px;
	color: #283262;
}
.yeewuna-detail-auteur-statistic-text span {
	font-weight: 600;
}
.yeewuna-detail-auteur-statistic-autheur {
	display: flex;
	align-items: center;
	gap: 30px;
}

.yeewuna-detail-autheur-titre-a-propos {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 33px;
	text-align: justify;
	color: #283262;
}
.yeewuna-detail-autheur-a-propos,
.yeewuna-detail-autheur-a-propos p {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 146.4%;
	color: #4f4f4f;
}
.yeewuna-detail-autheur-titre-a-propos-container {
	padding-top: 70px;
}
/* .ouvres-container {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
} */
.ouvres-container.row .yeewuna-livre-item, .ouvres-container.row .yeewuna-couverture-du-livres-container {
	width: 100% !important;
}
.custom-actu-container {
	cursor: pointer;
}

.yeewuna-details-actu-image {
	max-width: 361px;
	height: auto;
	width: 100%;
	max-height: 284.04px;
}
.yeewuna-details-actu-titre {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 32px;
	line-height: 44px;
	color: #000000;
}
.yeewuna-details-actu-container {
	padding-right: 90px;
}
.yeewuna-detaims-actu-para1 {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	text-align: justify;
	color: #6f767e;
}
.yeewuna-details-actu-image-container {
	float: left;
	padding-right: 25px;
}
