.card-profil-admin {
	background: #ffffff;
	box-shadow: 0px 4px 43px rgba(0, 0, 0, 0.1);
	padding: 20px;
}
.pp-profil-user-admin {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	object-fit: cover;
	border: 4px solid #283262;
  transition: all ease 300ms;
}
.pp-profil-user-admin:hover {
	border-color: #283262;
  transform: scale(1.1);
}
.name-pp-user-profil-admin {
	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	font-size: clamp(24px, 3vw, 40px);
	line-height: 32px;
	color: #0f2034;
}
.text-pp-user-profil-admin,
.text-pp-user-profil-admin p,
.text-pp-user-profil-admin span,
.text-pp-user-profil-admin label,
.text-pp-user-profil-admin b,
.text-pp-user-profil-admin h1,
.text-pp-user-profil-admin strong {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 32px;
	color: #0f2034;
	margin-left: auto;
	margin-right: auto;
	/* width: 70%; */
}
.libelle-text-contact-profil-admin {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	color: rgba(15, 32, 52, 0.4);
}
.content-contact-profil-admin {
	display: flex;
	align-items: center;
	gap: 10px;
}

.container-info-user-profil {
	position: relative;
}
.btn-edir-profil {
	height: 48px;
	background: #283262;
	border-radius: 10px;
	color: #ffffff;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	padding: 10px 60px;
}
.btn-edir-profil:hover {
	background: #ffffff;
	border: 1px solid #283262;
	color: #283262;
}
.info-user-profil-btn-edit-container {
	position: absolute;
	top: 20px;
	right: 20px;
}
.form-control-custom-textarea {
	border: 1px solid #bdbdbd;
	border-radius: 10px !important;
	color: #212529;
	padding: 20px;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
}
.form-control-modal-custom {
	background: #ffffff;
	border: 1px solid #bdbdbd;
	border-radius: 10px !important;
	color: #212529;
	padding: 20px;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
}
.form-label-modal-custom {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #0f2034;
}
.formulaire-midifier-profile-container {
	padding-top: 20px;
}

.admin-user-avatar-container {
	position: relative;
}
.admin-user-avatar-edit-hover {
	position: absolute;
	background: rgba(255, 255, 255, 0.5);
	width: 16%;
	height: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 50%;
	opacity: 0;
	transition: opacity 0.2s ease-in-out;
}
.admin-user-avatar-container:hover .admin-user-avatar-edit-hover {
	opacity: 1 !important;
	cursor: pointer;
}
.edit-avatar-camera-icon {
	margin-top: -18%;
	font-size: 35px;
	color: #ffffff;
}

.input-add-avatar {
	display: none;
}
.input-testt {
	display: flex;
	justify-content: center;
	align-items: center;
}

.admin-edit-profil-param-password-title {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #0f2034;
}
.admin-edit-profil-parametre-header-button {
	display: flex;
	align-items: center;
	font-family: "Raleway";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	color: #0c0b0b;
}
.btn-action-admin {
	min-width: 40px;
	max-width: 40px;
	min-height: 40px;
	border-radius: 50%;
	background: #283262;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}
.btn-action-admin:hover {
	border: 1px solid #283262;
	color: #283262;
	background: #fff;
	transition: all 0.2s;
}
.form-control-modal-custom {
	background: #ffffff;
	border: 1px solid #bdbdbd;
	border-radius: 10px !important;
	color: #212529;
	padding: 20px;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
}
.admin-custom-input-groupe {
	position: relative;
}
.admin-custom-showHideIcon {
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	right: 0px;
	z-index: 100;
	background: none !important;
	border: none !important;
	cursor: pointer;
}
.showPasswordIcon {
	color: #1f3cad;
}
.hidePasswordIcon {
	color: #9b9da6;
}
.container-user-info-contact-profil-admin {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 30px;
}
.img-couverture-livre {
	width: 100%;
}
