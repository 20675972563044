.navbar-vertical-customisation-admin {
  background: #ffffff !important;
}
.nav-link-vertival-custom-admin {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #6f767e;
  position: relative;
  padding: 15px 25px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.nav-link-vertival-custom-admin:hover,
.nav-link-vertival-custom-admin.active {
  background: #e59b34;
  transition: 0.2s ease;
  border-radius: 12px;
  color: #fff !important;
  font-weight: 700;
}

.nav-link-vertival-custom-admin.active:after {
  content: "";
  position: absolute;
  left: -25px;
  width: 10px;
  height: 100%;
  top: 0;
  border-radius: 0px 16px 16px 0px;
}
.nav-link-vertival-custom-admin svg {
  font-size: 20px;
}
