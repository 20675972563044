.post-card {
	background: #ffffff;
	border-radius: 10px;
	border: transparent;
    position: relative;
}
.post-card img {
	min-height: 250px;
	max-height: 250px;
	object-fit: cover;
}
.post-card h5.card-title {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 30px;
	color: #000000;
}
.post-card .card-text p {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #6f767e !important;
}
.post-card .card-desc {
	height: 5vh;
}
.post-relative-time {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 14px;
	color: #000000;
}
.post-card .actions-btn {
    display: none;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: absolute;
    background-color: rgba(0, 0, 0, .7);
    height: 100%;
    width: 100%;
    border-radius: 10px;
    transition: all ease-in-out 500ms;
}
.post-card:hover .actions-btn {
    display: flex;
}
.post-card .btn-action-item {
    background-color: #ffffff;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}
