.container-user-info-contact-profil-utilisateur {
 display: flex;
 justify-content: space-between;
 align-items: center;
 margin: auto;
 width: 60%;
}
.user-info-contact-profil-utilisateur-statistique {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: rgba(15, 32, 52, 0.4);
}
.user-info-contact-profil-utilisateur-statistique span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #0F2034;
}
.container-user-info-contact-profil-utilisateur-deux {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 2rem;
}
.content-info-contact-profil-admin {
    display: flex;
    flex-direction: column;
    align-items: center;
}