.message-btn {
    font-family: "Poppins";
    font-display: "swap";
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    color: #ffffff;
    letter-spacing: 0.5px;
    border-radius: 100px;
    text-transform: uppercase;
    border: none;
    text-align: center;
    border-radius: 100px;
}

.bg-status {
    background: #283262;
}

.admin-message-contact-card-frequence {
    font-family: "Poppins";
    font-display: "swap";
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 25px;
    letter-spacing: 0.4px;
    color: #9fa2b4;
}

.fz-14 {
    font-size: 14px !important;
}

.admin-message-contact-card-description,
.admin-message-contact-card-description p,
.admin-message-contact-card-description p span {
    font-family: "Poppins";
    font-display: "swap";
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 25px;
    letter-spacing: 0.4px;
}

.admin-message-contact-card {
    background: #ffffff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    padding-bottom: 40px;
}

.message-text p {
    color: #000000;
}

.accordion-button {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    padding: 1rem 1.5rem 1.25rem 10px;
}

.accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23A81912'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23A81912'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.admin-content-message,
.admin-content-message p {
    font-family: "Poppins";
    font-display: "swap";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 131.7%;
    color: rgba(0, 0, 0, 0.65);
}

.wayf-modal-title {
    font-family: "Poppins";
    font-display: "swap";
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    /* text-align: center; */
    color: #000000;
    margin: 0;
}

.admin-form-label {
    font-family: "Poppins";
    font-display: "swap";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #1b1b1b;
    margin-bottom: 0.3rem;
}

.admin-btn-typo {
    font-family: "Poppins";
    font-display: "swap";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
}

.admin-btn-add {
    background: #f1b927;
    border-radius: 5px;
    border: 1px solid #f1b927;
    padding: 0.3rem 3rem;
    color: #ffffff;
}

.admin-btn-add:hover {
    background: #ffffff;
    color: #f1b927;
}

.admin-btn-cancel {
    color: #000000;
    background-color: transparent;
    border: none;
}

.btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.text-response-card p {
    color: #000000;
    font-size: 15px;
    font-weight: 400;
}

.text-response-card {
    color: #000;
}

.ql-editor {
    min-height: 200px;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    color: #000000;
    font-size: 15px;
}

.form-label-custom {
    font-family: "Poppins";
    font-display: "swap";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    color: #1b1b1b;
}

.admin-form-control {
    border-radius: 5px;
}

.btn-theme-profil {
    font-family: "Poppins";
    font-display: "swap";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: #ffffff;
    background: #f1b927;
    border-radius: 5px;
    padding: 0.5rem;
}

.btn-close-theme {
    font-family: "Poppins";
    font-display: "swap";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: #000000;
    background: transparent;
}

.bg-status-secondary {
    background-color: #f1b927;
}